import React, { useState, useEffect, useContext } from "react";
import MatchSidebar from "../components/MatchSidebar";
import Rightbar from "../components/Rightbar";
import { apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import { useNavigate, Link } from "react-router-dom";
import OddsListing from "../components/OddsListing";
import Loader from "../assets/gif/loader.gif";
import helpers from "../utils/helpers";
import AuthContext from "../context/AuthContext";
import BetSlipContext from "../context/BetSlipContext";
import { Accordion } from "react-bootstrap";
import moment from "moment";
import io from "socket.io-client";
import { isEmpty } from "lodash";
const Soccer = () => {
  const navigate = useNavigate();
  let { handelAddMultiMarket, runApi } = useContext(BetSlipContext);
  let {
    user,
    inPlayScore,
    loginUserDetailData,
    setSoccerSocket,
    soccerSocket,
  } = useContext(AuthContext);
  // All State
  const [tournamentData, setTournamentData] = useState("");
  const [matches, setMatches] = useState([]);
  const [matchEnable, setMatchEnable] = useState(false);
  const [matchesWithId, setMatchesWithId] = useState("");
  const [getSeriesId, setSeriesId] = useState("");
  const [getSeriesName, setSeriesName] = useState("");
  const [getMatchName, setMatchName] = useState("");
  const [getMatchId, setMatchId] = useState("");
  const [getMarketId, setMarketId] = useState("");
  const [getAllSports, setAllSports] = useState([]);
  const [centralizedIds, setCentralizedIds] = useState("");
  const [isLoader, setLoader] = useState(false);
  const [searchType, setSearchType] = useState("time");
  const [getMatchWithSeries, setMatchWithSeries] = useState([]);
  const [originalMatched, setOriginalMatched] = useState([]);
  const getTournamentData = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.seriesList + "?gameType=soccer"
    );
    if (status === 200) {
      if (response_users.success) {
        setTournamentData(response_users.results);
      }
    }
  };

  const handelAll = async () => {
    const { status, data: response_users } = await apiGet(apiPath.sportsList);
    if (status === 200) {
      if (response_users.success) {
        setAllSports(response_users.results);
      }
    }
  };
  let userId = "";
  if (user?.user?._id) {
    userId = "&userId=" + user?.user?._id;
  }
  const getMatches = async (id) => {
    try {
      setLoader(true);
      id && setSeriesId(id);
      const { status, data: response_users } = await apiGet(
        `${
          !id
            ? apiPath.matchList + "?gameType=soccer" + userId
            : apiPath.matchList + "?gameType=soccer" + "&seriesId=" + id
        }`
      );
      if (status === 200) {
        if (response_users.success) {
          id
            ? setMatchesWithId(response_users.results)
            : inPlayScore?.length > 0
            ? setMatches(
                response_users?.results?.map((res) => {
                  let temp = inPlayScore.find(
                    (item) => item?.eventId == res?.eventId
                  );
                  return {
                    ...res,
                    score: temp?.eventId == res?.eventId ? temp?.score : "",
                    timeElapsed:
                      temp?.eventId == res?.eventId ? temp?.timeElapsed : "",
                  };
                })
              )
            : setMatches(response_users.results);

          id
            ? setMatchesWithId(response_users.results)
            : inPlayScore?.length > 0
            ? setOriginalMatched(
                response_users?.results?.map((res) => {
                  let temp = inPlayScore.find(
                    (item) => item?.eventId == res?.eventId
                  );
                  return {
                    ...res,
                    score: temp?.eventId == res?.eventId ? temp?.score : "",
                    timeElapsed:
                      temp?.eventId == res?.eventId ? temp?.timeElapsed : "",
                  };
                })
              )
            : setOriginalMatched(response_users.results);

          const marketIds = response_users.results.map((r) => r.centralizedId);
          if (marketIds && marketIds.length) {
            setCentralizedIds(marketIds);
          }
          if (response_users?.results?.length > 0) {
            getOdds(
              response_users?.results?.map((res) => {
                return res?.marketId;
              })
            );
          }
          setLoader(false);
        } else {
          setLoader(false);
        }
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const handelClickOddDetails = (matchId, marketId) => {
    navigate("/markets/soccer/" + matchId + "/" + marketId);
  };

  useEffect(() => {
    getMatches();
    getTournamentData();
  }, []);

  useEffect(() => {
    getMatches();
  }, [runApi]);

  const handelSearchBy = (searchTypeValue) => {
    if (searchTypeValue === "competition") {
      setSearchType("competition");
      const groupBySeriesName = matches.reduce((group, product) => {
        const seriesName = product?.seriesName;
        if (seriesName) {
          group[seriesName] = group[seriesName] ?? [];
          group[seriesName].push(product);
        } else {
          group["--"] = group["--"] ?? [];
          group["--"].push(product);
        }
        return group;
      }, {});
      setMatchWithSeries(Object.entries(groupBySeriesName).sort());
    } else if (searchTypeValue === "matched") {
      setSearchType("matched");
      setMatchWithSeries([]);
      setMatches([
        ...matches
          ?.filter((res) => {
            return !isEmpty(res?.odds);
          })
          .sort(function (a, b) {
            return b?.odds?.totalMatched - a?.odds?.totalMatched;
          }),
        ...matches?.filter((res) => {
          if (isEmpty(res?.odds)) {
            return res;
          }
        }),
      ]);
    } else {
      setSearchType("time");
      setMatches(originalMatched);
      setMatchWithSeries([]);
    }
  };

  useEffect(() => {
    if (inPlayScore?.length > 0) {
      setMatches((current) => {
        return current.map((res) => {
          let temp = inPlayScore.find((item) => item?.eventId == res?.eventId);
          return {
            ...res,
            score: temp?.eventId == res?.eventId ? temp?.score : "",
            timeElapsed: temp?.eventId == res?.eventId ? temp?.timeElapsed : "",
          };
        });
      });
    }
  }, [inPlayScore]);

  const [checkRefresh, setCheckRefresh] = useState(true);
  const [socketObj, setSocketObj] = useState(null);
  const [score, setScore] = useState([]);
  const getScore = (message) => {
    setScore(message);
  };
  const getSocket = () => {
    if (checkRefresh) {
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
      if (!isEmpty(soccerSocket)) {
        soccerSocket.disconnect();
      }
      setCheckRefresh(false);
      let newSocket = "";
      let randomId = user?.user?._id ? user?.user?._id : 112233;
      if (!isEmpty(user)) {
        newSocket = io(
          `${process.env.REACT_APP_API_BASE_URL2}?token=${randomId}&userType=front`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      } else {
        newSocket = io(
          `${process.env.REACT_APP_API_BASE_URL2}?user_id=${randomId}`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      }

      setSocketObj(newSocket);
      setSoccerSocket(newSocket);
      newSocket.on("scoreAll", getScore);
      newSocket.on("disconnect", function () {
        setCheckRefresh(true);
      });
      return () => newSocket.close();
    }
  };

  useEffect(() => {
    if (checkRefresh) {
      getSocket();
    }
  }, [checkRefresh]);

  document.addEventListener("visibilitychange", function () {
    if (!document.hidden && !checkRefresh) {
      setCheckRefresh(true);
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
    } else {
      setCheckRefresh(false);
    }
  });

  const getOdds = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOdds + `?marketId=${id?.join(",")}&multi=true`
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results?.length > 0) {
          setMatches((current) =>
            current.map((obj) => {
              let newObj = response_users?.results?.find((res) => {
                return res?.mi == obj?.marketId;
              });
              if (obj?.marketId == newObj?.mi) {
                return { ...obj, odds: newObj };
              }
              return obj;
            })
          );
          setOriginalMatched((current) =>
            current.map((obj) => {
              let newObj = response_users?.results?.find((res) => {
                return res?.mi == obj?.marketId;
              });
              if (obj?.marketId == newObj?.mi) {
                return { ...obj, odds: newObj };
              }
              return obj;
            })
          );
        }
      }
    }
  };

  return (
    <>
      <div className="full-wrap">
        <MatchSidebar
          matchType="soccer"
          tournamentData={tournamentData}
          handelBackFunc={(id) => {
            setSeriesId();
            setSeriesName();
            getTournamentData();
          }}
          handelClickFunc={(id) => {
            getMatches(id);
            setAllSports();
            setTournamentData();
            setMatchName();
          }}
          matchesWithId={matchesWithId}
          setSeriesName={(seriesName) => {
            setSeriesName(seriesName);
            setMatchName();
          }}
          setMatchName={(matchName) => {
            setMatchName(matchName);
            setMatchesWithId();
          }}
          setMatchId={(matchId) => {
            setMatchId(matchId);
            setMatchesWithId();
          }}
          setMarketId={(marketId) => {
            setMarketId(marketId);
          }}
          seriesName={getSeriesName}
          matchName={getMatchName}
          matchId={getMatchId}
          marketId={getMarketId}
          getSeriesId={getSeriesId}
          handelClickOddDetails={(matchId, marketId) =>
            handelClickOddDetails(matchId, marketId)
          }
          handelAll={() => {
            handelAll();
            setSeriesId();
            setMatchName();
          }}
          AllSports={getAllSports}
          handelSportFunc={(id) => {
            getMatches(id);
            setAllSports();
            getTournamentData();
          }}
        />
        <div className="col-center games_center_block">
          <div className="game_banner">
            <img src="../assets/images/kv_tennis.jpg" className="w-100" />
          </div>
          <div className="game_table_main_wrapper">
            <div className="game_list_filter_shape">
              <h4>Sports Highlights</h4>
              <div className="game_filter_filter_right">
                <label>View by</label>
                <select
                  value={searchType}
                  onChange={(e) => handelSearchBy(e.target.value)}
                >
                  <option
                    value="competition"
                    selected={searchType === "competition"}
                  >
                    Competition
                  </option>
                  <option value="time" selected={searchType === "time"}>
                    Time
                  </option>
                  <option value="matched" selected={searchType === "matched"}>
                    Matched
                  </option>
                </select>
              </div>
            </div>
            {searchType === "competition" ? (
              getMatchWithSeries.length > 0 && (
                <div className="theme_accordian">
                  {getMatchWithSeries &&
                    getMatchWithSeries.map((t, i) => {
                      return (
                        <Accordion defaultActiveKey="bets">
                          <Accordion.Item eventKey="bets">
                            <Accordion.Header>{t[0]}</Accordion.Header>
                            <Accordion.Body>
                              <div className="game-list">
                                {t[1] &&
                                  t[1].length > 0 &&
                                  t[1].map((item, index) => {
                                    let obj = moment.duration(
                                      moment(item?.eventDateTime).diff(
                                        moment(new Date())
                                      )
                                    )._data;
                                    return (
                                      <dl
                                        className="game-list-col d-flex"
                                        key={index + 1}
                                      >
                                        <dt className="col-matched">
                                          <Link
                                            to={`/markets/soccer/${item.eventId}/${item.marketId}`}
                                            state={{
                                              item,
                                              seriesName: item?.seriesName,
                                            }}
                                          >
                                            <span className="d-flex">
                                              {item?.status == "in_play" ? (
                                                <img
                                                  className="icon-in_play"
                                                  src="../assets/images/transparent.gif"
                                                  alt="No_Image"
                                                />
                                              ) : (
                                                <img
                                                  className="icon-no_play"
                                                  src="../assets/images/transparent.gif"
                                                />
                                              )}
                                              <span className="game-low_liq d-none">
                                                Low Liquidity
                                              </span>
                                              <span className="game-titlesmall">
                                                {/* FC Tokyo<span className="blue_color">V</span>C-Osaka */}
                                                {item?.score
                                                  ? `${
                                                      item?.eventName.split(
                                                        "v"
                                                      )[0]
                                                    } ${item?.score} ${
                                                      item?.eventName.split(
                                                        "v"
                                                      )[1]
                                                    }`
                                                  : item?.eventName}
                                              </span>
                                            </span>
                                            {obj.days == 0 &&
                                            obj.hours == 0 &&
                                            obj?.years == 0 &&
                                            obj?.months == 0 &&
                                            obj.minutes > 0 &&
                                            obj?.minutes <
                                              loginUserDetailData?.beforeInPlay ? (
                                              <span className="game-list-time">
                                                Starting in {obj?.minutes}'
                                              </span>
                                            ) : item.status == "in_play" ? (
                                              <span className="in_play">
                                                In-Play {item?.timeElapsed}
                                              </span>
                                            ) : (
                                              <span className="game-list-time">
                                                {helpers.matchDateTime(
                                                  item.eventDateTime
                                                )}
                                              </span>
                                            )}

                                            {item?.channel !== "false" && (
                                              <span className="game-live"></span>
                                            )}
                                            {/*
                                        <span className="game-E d-none">
                                          <i></i>Soccer
                                        </span>
                                        <span className="game-E d-none">
                                          <i></i>Cricket
                                        </span>
                                        <span className="game-E d-none">
                                          <i></i>Tennis
                                        </span>{" "}
                                        */}

                                            {item?.fancyList &&
                                              item?.fancyList.length > 0 && (
                                                <span className="game-fancy in-play"></span>
                                              )}
                                            {item?.bookmakerRunners &&
                                              item?.bookmakerRunners.length >
                                                0 && (
                                                <span className="game-bookmaker in-play"></span>
                                              )}
                                            {item?.bookmakerRunners &&
                                              item?.bookmakerRunners?.length >
                                                0 &&
                                              item?.runners &&
                                              item?.runners?.length > 0 &&
                                              item?.fancyList &&
                                              item?.fancyList.length > 0 && (
                                                <span className="game-sportsbook">
                                                  {" "}
                                                </span>
                                              )}
                                          </Link>
                                        </dt>

                                        <dd className="col-mdl text-end">
                                          USD{" "}
                                          {!isEmpty(item?.odds)
                                            ? USDollar.format(
                                                item?.odds?.totalMatched
                                              )
                                            : "0"}
                                        </dd>

                                        <OddsListing matchData={item} />

                                        <dd className="col-info">
                                          <Link
                                            href="#"
                                            className={
                                              item?.multiMarket
                                                ? "add-pin-position"
                                                : "add-pin"
                                            }
                                            onClick={() =>
                                              handelAddMultiMarket(
                                                item?.eventId
                                              )
                                            }
                                          ></Link>
                                        </dd>
                                      </dl>
                                    );
                                  })}
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      );
                    })}
                </div>
              )
            ) : isLoader ? (
              <span className="d-flex justify-content-center align-item-center mt-2 pb-3">
                <img src={Loader} alt="No_Image" />
              </span>
            ) : matches.length > 0 || matchEnable ? (
              <div className="game_table_main_wrapper">
                <div className="game_table_inner">
                  <ul className="slip-head d-flex">
                    <li className="col-game"></li>

                    <li className="col-matched text-end">Matched</li>

                    <li className="col-visit text-center">1</li>
                    <li className="col-draw text-center">x</li>
                    <li className="col-home text-center">2</li>
                    <li className="col-info"></li>
                  </ul>
                  <div className="game-list">
                    {matches.map((item, index) => {
                      let obj = moment.duration(
                        moment(item?.eventDateTime).diff(moment(new Date()))
                      )._data;

                      let scoreNew =
                        score?.length > 0
                          ? score?.find((res) => {
                              return res?.eventId == item?.eventId;
                            })
                          : {};
                      return (
                        <dl className="game-list-col d-flex" key={index + 1}>
                          <dt className="col-matched">
                            <Link
                              to={`/markets/soccer/${item.eventId}/${item.marketId}`}
                              state={{ item, seriesName: item?.seriesName }}
                            >
                              <span className="d-flex">
                                {item?.status == "in_play" ? (
                                  <img
                                    className="icon-in_play"
                                    src="../assets/images/transparent.gif"
                                    alt="No_Image"
                                  />
                                ) : (
                                  <img
                                    className="icon-no_play"
                                    src="../assets/images/transparent.gif"
                                  />
                                )}
                                <span className="game-low_liq d-none">
                                  Low Liquidity
                                </span>
                                {!isEmpty(scoreNew) ? (
                                  <span className="game-titlesmall">
                                    {item?.eventName.split("v")[0]}
                                    <span className="in_play">
                                      {scoreNew?.score}
                                    </span>
                                    {item?.eventName.split("v")[1]}
                                  </span>
                                ) : (
                                  <span className="game-titlesmall">
                                    {item?.eventName}
                                  </span>
                                )}
                              </span>
                              {obj.days == 0 &&
                              obj.hours == 0 &&
                              obj?.years == 0 &&
                              obj?.months == 0 &&
                              obj.minutes > 0 &&
                              obj?.minutes <
                                loginUserDetailData?.beforeInPlay ? (
                                <span className="game-list-time">
                                  Starting in {obj?.minutes}'
                                </span>
                              ) : item.status == "in_play" ? (
                                <span className="in_play">
                                  In-Play {item?.timeElapsed}
                                </span>
                              ) : (
                                <span className="game-list-time">
                                  {helpers.matchDateTime(item.eventDateTime)}
                                </span>
                              )}

                              {item?.channel !== "false" && (
                                <span className="game-live"></span>
                              )}
                              {/*
                                        <span className="game-E d-none">
                                          <i></i>Soccer
                                        </span>
                                        <span className="game-E d-none">
                                          <i></i>Cricket
                                        </span>
                                        <span className="game-E d-none">
                                          <i></i>Tennis
                                        </span>{" "}
                                        */}

                              {item?.fancyList &&
                                item?.fancyList.length > 0 && (
                                  <span className="game-fancy in-play"></span>
                                )}
                              {item?.bookmakerRunners &&
                                item?.bookmakerRunners.length > 0 && (
                                  <span className="game-bookmaker in-play"></span>
                                )}
                              {item?.bookmakerRunners &&
                                item?.bookmakerRunners?.length > 0 &&
                                item?.runners &&
                                item?.runners?.length > 0 &&
                                item?.fancyList &&
                                item?.fancyList.length > 0 && (
                                  <span className="game-sportsbook"> </span>
                                )}
                            </Link>
                          </dt>
                     
                          <dd className="col-mdl text-end">
                            USD{" "}
                            {!isEmpty(item?.odds)
                              ? USDollar.format(item?.odds?.totalMatched)
                              : "0"}
                          </dd>
                          <OddsListing matchData={item} />

                          <dd className="col-info">
                            <Link
                              href="#"
                              className={
                                item?.multiMarket
                                  ? "add-pin-position"
                                  : "add-pin"
                              }
                              onClick={() =>
                                handelAddMultiMarket(item?.eventId)
                              }
                            ></Link>
                          </dd>
                        </dl>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : matches.length <= 0 ? (
              "No Match Found"
            ) : (
              <span className="d-flex justify-content-center align-item-center mt-2 pb-3">
                <img src={Loader} alt="No_Image" />
              </span>
            )}
          </div>
        </div>
        {/* right_cols */}
        <Rightbar />
      </div>
    </>
  );
};

export default Soccer;

let USDollar = new Intl.NumberFormat("en-US", {
  currency: "USD",
});
