import React, { useState, useContext, useEffect, useMemo } from "react";
import { Container, Row, Col, Tabs, Tab, Form, Table } from "react-bootstrap";
import Sidebar from "../components/Sidebar";
import AuthContext from "../context/AuthContext";
import { apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import { debounce, isEmpty, startCase } from "lodash";
import helpers from "../utils/helpers";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import obj from "../utils/helpers";

const Result = () => {
  const [list, setList] = useState([]);
  var previousDate = moment().subtract(1, "days").format("YYYY-MM-DD");
  var currentDate = moment().format("YYYY-MM-DD");
  const [filter, setFilter] = useState({
    fromPeriod: previousDate,
    toPeriod: currentDate,
    keyword: "",
    page: 1,
    limit: 100,
  });
  const handel = (e) => {
    setFilter((prev) => {
      return { ...prev, keyword: e };
    });
  };
  function handleChange(event) {
    debounceFn(event);
  }
  const debounceFn = useMemo(() => debounce(handel, 1000), []);

  const getTournamentData = async (type) => {
    const { status, data: response_users } = await apiGet(apiPath.resultList, {
      ...filter,
      gameType: type,
    });
    if (status === 200) {
      if (response_users.success) {
        setList(response_users?.results);
      }
    }
  };

  useEffect(() => {
    getTournamentData();
  }, []);
  useEffect(() => {
    getTournamentData();
  }, [filter?.toPeriod, filter?.keyword, filter?.page, filter?.fromPeriod]);
  const location = useLocation();
  return (
    <>
      <section className="py-4 main-inner-outer">
        <Container
          className="d-flex justify-content-center align-items-center"
          fluid
        >
          <div className="main_content_row w-75">
            <div className="my_account_sidebar">
              <div className="sidebar">
                <div className="sidebar-main">
                  <div className="sub_path">
                    <div className="path">
                      <a href="#path-pop" className="path-back">
                        ...
                      </a>
                      <ul>
                        <li className="path-last">
                          <Link to="#">Check Result</Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <ul className="menu-list list-unstyled">
                    <li>
                      <Link
                        id="sub_menu_detail active"
                        to={
                          location?.pathname?.split("/")[2] == "mobile"
                            ? `/my-profile/mobile`
                            : "/my-profile"
                        }
                      >
                        Result
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="my_account_main">
              <div className="inner-wrapper">
                <h2 className="common-heading">Check Result</h2>
                <div className="resul-filter">
                  <div className="result-time">
                    <div>
                    <span style={{ marginRight: "8px", fontSize: "12px" }}>
                        Period
                      </span>
                      <input
                        type="date"
                        onChange={(e) =>
                          setFilter({
                            ...filter,
                            fromPeriod: e.target.value,
                          })
                        }
                        max={new Date().toISOString().split("T")[0]}
                        value={filter.fromPeriod}
                      />
                    </div>
                    <div>
                    <span style={{ marginRight: "8px", fontSize: "12px" }}>
                        to
                      </span>
                      <input
                        type="date"
                        onChange={(e) =>
                          setFilter({
                            ...filter,
                            toPeriod: e.target.value,
                          })
                        }
                        min={
                          filter?.fromPeriod
                            ? new Date(filter?.fromPeriod)
                                .toISOString()
                                .split("T")[0]
                            : new Date()
                        }
                        disabled={filter.fromPeriod ? false : true}
                        max={new Date().toISOString().split("T")[0]}
                        value={filter.toPeriod}
                      />
                    </div>
                  </div>
                  <div style={{ padding: "7px" }}>
                    <button
                      onClick={() => getTournamentData("cricket")}
                      className="btn-result"
                    >
                      Cricket
                    </button>
                    <button
                      onClick={() => getTournamentData("soccer")}
                      className="btn-result"
                    >
                      Soccer
                    </button>
                    <button
                      onClick={() => getTournamentData("tennis")}
                      className="btn-result"
                    >
                      Tennis
                    </button>
                    <button className="btn-result">Virtual T10</button>
                  </div>
                </div>
                <div className="d-flex justify-content-end align-items-center mb-4 mt-4">
                  <input
                    placeholder="Search..."
                    onChange={(e) => handleChange(e.target.value)}
                    type="text"
                    className="w-25"
                  />
                </div>

                <table className="result-table w-100">
                  <thead>
                    <tr>
                      <th style={{ width: "7%", textAlign: "start" }}>
                        Sr No.
                      </th>{" "}
                      <th className="text-start">Sport</th>{" "}
                      <th style={{ width: "18%", textAlign: "start" }}>
                        Match Date
                      </th>{" "}
                      <th style={{ width: "55%", textAlign: "start" }}>
                        Match Name
                      </th>{" "}
                      <th style={{ width: "15%", textAlign: "start" }}>
                        Winner
                      </th>{" "}
                    </tr>
                  </thead>

                  <tbody>
                    {list?.data?.map((res, index) => {
                      return (
                        <tr>
                          <td className="text-start">{index + 1}</td>
                          <td className="text-start">
                            {startCase(res?.gameType)}
                          </td>{" "}
                          <td className="text-start">
                            {obj.dateFormat(res?.eventDateTime)}
                          </td>
                          <td className="text-start">{`${res?.eventName} - (${res?.eventId})`}</td>
                          <td className="text-start">
                            {
                              res?.runners?.find((item) => {
                                return item?.SelectionId == res?.winner;
                              })?.RunnerName
                            }
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {list?.data?.length > 0 && (
                  <div className="load-more">
                    <button
                      className="btn-result"
                      style={{ width: "150px" }}
                      onClick={() => {
                        if (list?.hasNextPage) {
                          setFilter((pre) => {
                            return { ...pre, page: list?.nextPage };
                          });
                        }
                      }}
                    >
                      Load More
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Result;
