import React, { useState } from "react";
import { Row, Col, Form, Table } from "react-bootstrap";
import moment from "moment";
import obj, { headerData } from "../../utils/constants";
import constants from "../../utils/helpers";
import Loader from "../../assets/gif/loader.gif";
import { startCase } from "lodash";

const Exchange = ({ title, data, isLoader }) => {
  const [showList, setShowList] = useState("");
  return (
    <div className="common-container">
      {/* <Form className="bet_status mb-3">
        <Row>
          <Col xl={11} md={12}>
            <Row className="align-items-center">
              <Col lg={3} sm={6} className="mb-lg-0 mb-3">
                <div className="bet-sec d-flex align-items-center">
                  <Form.Label className="mb-0">Bet Status:</Form.Label>
                  <Form.Select aria-label="Default select example">
                    <option>All</option>
                    <option value="1">UnMatched</option>
                    <option value="2">Matched</option>
                  </Form.Select>
                </div>
              </Col>
              <Col lg={4}>
                <div className="d-flex align-items-center">
                  <Form.Label className="mb-0 me-2">Order By:</Form.Label>
                  <Form.Check
                    aria-label="option 1"
                    label="Bet Placed"
                    className="mb-0 me-2 d-flex align-items-center"
                  />
                  <Form.Check
                    aria-label="option 1"
                    label="Market"
                    className="mb-0 me-2 d-flex align-items-center"
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form> */}
      {title == "parlay" ? (
        <section className="account-table my-bet-sec mt-2">
          <div className="profile-tab">
            <div className="my_account_table_wrap">
              <h5>Matched</h5>
              <div className="table-main-wrapper">
                <Table className="table_caption_style">
                  <thead>
                    <tr>
                      <th scope="col" className="text-start">
                        Market
                      </th>
                      <th scope="col" className="text-end">
                        {" "}
                        Settled Date
                      </th>
                      <th scope="col" className="text-end">
                        {" "}
                        Bet ID
                      </th>
                      <th scope="col" className="text-end">
                        Stake
                      </th>
                      <th scope="col" className="text-end">
                        Odds
                      </th>
                      <th scope="col" className="text-end">
                        Profit / Loss
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.length > 0 ? (
                      data?.map((item) => {
                        return (
                          <>
                            <tr>
                              <td className="text-start">
                                {item?.isDeclared ? (
                                  <>
                                    {item?.result == "loss" ? (
                                      <strong
                                        // className="d-block"
                                        style={{ color: "red" }}
                                      >
                                        {startCase(item?.result)}
                                      </strong>
                                    ) : (
                                      <strong
                                        // className="d-block"
                                        style={{ color: "green" }}
                                      >
                                        {startCase(item?.result)}
                                      </strong>
                                    )}
                                  </>
                                ) : (
                                  "..."
                                )}
                                <span className="angle_unicode">▸</span>
                                <strong>
                                  {item?.bets?.length == 0
                                    ? ""
                                    : item?.bets?.length == 1
                                    ? "SINGLE"
                                    : item?.bets?.length == 2
                                    ? "DOUBLE"
                                    : item?.bets?.length == 3
                                    ? "TREBLE"
                                    : "ACCMULATOR"}
                                </strong>
                              </td>
                              <td id="settledDate" className="text-end">
                                {" "}
                                {constants.dateFormat(item.timeInserted)}
                              </td>
                              <td id="settledDate" className="text-end">
                                {" "}
                                {item?.matchBetId}
                              </td>
                              <td id="settledDate" className="text-end">
                                {" "}
                                {Number(item?.amount)?.toFixed(2)}
                              </td>
                              <td id="settledDate" className="text-end">
                                {" "}
                                {Number(item?.bhav)?.toFixed(2)}
                              </td>
                              <td className="text-end">
                                <a id="pl0" className="expand-open" href="#">
                                  {item?.isDeclared ? (
                                    <>
                                      {item?.result == "loss" ? (
                                        <strong style={{ color: "red" }}>
                                          {Number(item?.loseAmount)?.toFixed(2)}
                                        </strong>
                                      ) : (
                                        <strong style={{ color: "green" }}>
                                          {Number(item?.profitAmount)?.toFixed(
                                            2
                                          )}
                                        </strong>
                                      )}
                                    </>
                                  ) : (
                                    "..."
                                  )}
                                </a>
                                <i
                                  id={"icon_" + item?._id}
                                  className={
                                    showList == item?._id
                                      ? "fas fa-minus-square"
                                      : "fas fa-plus-square"
                                  }
                                  onClick={(e) => {
                                    if (showList == item?._id) {
                                      setShowList("");
                                    } else {
                                      setShowList(item?._id);
                                    }
                                  }}
                                ></i>
                              </td>
                            </tr>
                            {item?.bets?.length > 0 &&
                              showList == item?._id && (
                                <tr
                                  className="expand"
                                  id={item?._id}
                                  key={item?._id}
                                >
                                  <td colSpan="5" className="expand_wrap">
                                    <table className="table-commission">
                                      <tbody>
                                        <tr>
                                          {/* <th>User Name</th> */}
                                          <th className="text-start">
                                            Fancy Name
                                          </th>
                                          <th className="text-end">
                                            Selection
                                          </th>
                                          <th className="text-end">Odds</th>
                                          <th className="text-end">Win/Loss</th>
                                        </tr>
                                        {item?.bets?.map((betData, i) => {
                                          return (
                                            <tr
                                              id="txRow0"
                                              style={{ display: "table-row" }}
                                              className="even"
                                              key={i + 1}
                                            >
                                              {/* <td id="betID">
                                                {betData?.clientName}
                                              </td> */}
                                              <td id="betID">
                                                {betData?.fancyName}
                                              </td>
                                              <td
                                                className="text-end"
                                                id="matchSelection"
                                              >
                                                {betData?.runnerName}
                                              </td>
                                              <td
                                                className="text-end"
                                                id="txOddsMatched"
                                              >
                                                {betData?.bhav}
                                              </td>
                                              <td
                                                id="placed"
                                                className="text-end"
                                              >
                                                {" "}
                                                {/* {Number(
                                                betData?.result == "win"
                                                  ? betData?.profitAmount
                                                  : betData?.loseAmount
                                              )?.toFixed(2)} */}
                                                {betData?.isDeclared ? (
                                                  <>
                                                    {betData?.result ==
                                                    "loss" ? (
                                                      <strong
                                                        style={{
                                                          color: "red",
                                                        }}
                                                      >
                                                        {startCase(
                                                          betData?.result
                                                        )}
                                                      </strong>
                                                    ) : (
                                                      <strong
                                                        style={{
                                                          color: "green",
                                                        }}
                                                      >
                                                        {startCase(
                                                          betData?.result
                                                        )}
                                                      </strong>
                                                    )}
                                                  </>
                                                ) : (
                                                  "..."
                                                )}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              )}
                          </>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="12" className="text-start">
                          <span>You have no bets in this time period.</span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="account-table my-bet-sec mt-2">
          <div className="profile-tab">
            {title === "Exchange" && (
              <div className="my_account_table_wrap">
                <h5>UnMatched</h5>
                <div className="table-main-wrapper">
                  <Table className="table_caption_style">
                    <thead>
                      <tr>
                        {headerData.map((res) => {
                          return (
                            <th
                              scope="col"
                              className={res.class}
                              width={res.widht}
                            >
                              {res.title}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {data && data?.unMatched?.length > 0 ? (
                        data?.unMatched?.map((item) => {
                          return (
                            <tr>
                              <td className="text-start" width="30%">
                                {item?.matchName || "N/A"}
                              </td>
                              <td className="text-end">
                                {obj.betCheckObj[item?.eventType]}
                              </td>
                              <td className="text-end">
                                {" "}
                                {item?.selectionId || "N/A"}
                              </td>
                              <td className="text-end">
                                {" "}
                                {item?.betType || item?.type}
                              </td>
                              <td className="text-end">
                                {" "}
                                {item?.matchBetId || "N/A"}
                              </td>
                              <td className="text-end">
                                {" "}
                                {item?.amount || "N/A"}
                              </td>
                              <td className="text-end">
                                {" "}
                                {item?.bhav || "N/A"}
                              </td>
                              <td className="text-end">
                                {" "}
                                {item?.matchName || "N/A"}{" "}
                              </td>
                              <td className="text-end">
                                {" "}
                                {item?.matchName || "N/A"}{" "}
                              </td>
                              <td>
                                {item?.betType == "back" ||
                                item?.type == "Yes" ? (
                                  <span className="text-success">
                                    {item?.profitAmount}
                                  </span>
                                ) : (
                                  <span className="text-danger">
                                    -({item?.loseAmount})
                                  </span>
                                )}
                              </td>
                              <td>
                                {" "}
                                {moment(item?.timeInserted).format(
                                  "YYYY-MM-DD, h:mm:ss"
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="12" className="text-start">
                            <span>You have no bets in this time period.</span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            )}
            <div className="my_account_table_wrap">
              <h5>Matched</h5>
              <div className="table-main-wrapper">
                <Table className="table_caption_style">
                  <thead>
                    <tr>
                      {headerData.map((res) => {
                        return (
                          <th
                            scope="col"
                            className={res.class}
                            width={res.widht}
                          >
                            {res.title}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>

                  <tbody>
                    {data &&
                    (title === "Exchange" ? data?.matched : data)?.length >
                      0 ? (
                      (title === "Exchange" ? data?.matched : data)?.map(
                        (item) => {
                          return (
                            <tr>
                              <td className="text-start" width="30%">
                                {item?.matchName}
                                {title === "FancyBet" || title === "Fancy1Bet"
                                  ? " > " + item?.fancyName
                                  : ""}
                              </td>
                              <td className="text-end">
                                {obj?.betCheckObj[item?.eventType]}
                              </td>
                              <td className="text-end">
                                {" "}
                                {title === "FancyBet"
                                  ? item?.betRun + "/" + item?.bhav
                                  : title === "Fancy1Bet"
                                  ? item?.runnerName
                                  : item?.teamName}
                              </td>
                              <td className="text-end">
                                {" "}
                                {title === "FancyBet"
                                  ? item?.betRun
                                  : item?.betType}
                              </td>
                              <td className="text-end">
                                {" "}
                                {title === "FancyBet"
                                  ? item?.sessionBetId
                                  : item?.matchBetId}
                              </td>
                              <td className="text-end">
                                {" "}
                                {item?.amount || "N/A"}
                              </td>
                              <td className="text-end">
                                {" "}
                                {item?.bhav || "N/A"}
                              </td>
                              <td className="text-end">{"N/A"}</td>
                              <td className="text-end">
                                {" "}
                                {item?.bhav || "N/A"}{" "}
                              </td>
                              <td>
                                {item?.betType == "back" ||
                                item?.type == "Yes" ? (
                                  <span className="text-success">
                                    {item?.profitAmount}
                                  </span>
                                ) : (
                                  <span className="text-danger">
                                    -({item?.loseAmount})
                                  </span>
                                )}
                              </td>
                              <td>
                                {" "}
                                {moment(item?.timeInserted).format(
                                  "YYYY-MM-DD, h:mm:ss"
                                )}
                              </td>
                            </tr>
                          );
                        }
                      )
                    ) : (
                      <tr>
                        <td colSpan="12" className="text-start">
                          <span>You have no bets in this time period.</span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};
export default Exchange;
