import React, { useState, useContext, useRef, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import AuthContext from "../context/AuthContext";
import { isEmpty } from "lodash";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { apiGet ,apiPost} from "../utils/apiFetch";
import apiPath from "../utils/apiPath";

function Home() {
  let { user, handleShow, launchCasino, homeLoader,user_coins,dolaunchCasinoNew } = useContext(AuthContext);
  const navigate = useNavigate();
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
  };
  const [inplayCountData, setInplayCountData] = useState({});

  const inPlayCount = async () => {
    const { status, data: response_users } = await apiGet(apiPath.inPlayCount);
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results) {
          setInplayCountData(response_users.results);
        }
      }
    }
  };
  const [data, setData] = useState([]);

  const getData = async () => {
    const { status, data } = await apiPost(apiPath.getBanner, {
      type: "home_top",
    });
    if (status == 200) {
      if (data?.success) {
        setData(data?.results?.banners);
      }
    }
  };
 
  useEffect(() => {
    getData();
    inPlayCount();
  }, []);

  return (
    <div>
      <div className="main">
        <Container>
          {/* {homeLoader && (
            <span className="d-flex justify-content-center align-item-center mt-2 pb-3">
              <img src={Loader} alt="No_Image" />
            </span>)} */}
          
            <Col md={12} className="my-2">
            <Slider {...settings}>
                    {data?.length > 0 &&
                      data?.map((item) => {
                        return (
                      <div>
                        <LazyLoadImage
                        src={process.env.REACT_APP_API_BASE_URL + item?.banner_path}
                          
                          style={{ width: "100%" }}
                          alt=""
                        />
                      </div>
                    );
                    })}
                    
                    </Slider>
            </Col>
            <div className="gamehall-wrap-simple">
            <a href="#" neua="InPlay Banner">
              <dl className="entrance-title">
                <dt>Sports</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
              <dl
                id="onLiveBoard"
                onClick={() => navigate("/inplay")}
                className="on_live"
              >
                <dt>
                  <p className="live_icon">
                    <span></span> LIVE
                  </p>
                </dt>

                <dd id="onLiveCount_CRICKET">
                  <p>Cricket</p>
                  <span id="count">
                    {inplayCountData?.cricketInplayCount || 0}
                  </span>
                </dd>

                <dd id="onLiveCount_SOCCER">
                  <p>Soccer</p>
                  <span id="count">
                    {inplayCountData?.soccerInplayCount || 0}
                  </span>
                </dd>

                {/* <dd id="onLiveCount_E_SOCCER">
                  <p>E-Soccer</p>
                  <span id="count">
                    {inplayCountData?.soccerInplayCount || 0}
                  </span>
                </dd> */}

                <dd id="onLiveCount_TENNIS">
                  <p>Tennis</p>
                  <span id="count">
                    {inplayCountData?.tennisInplayCount || 0}
                  </span>
                </dd>
              </dl>
              <Link to="/inplay">
                <img 
                  src={
                    process.env.REACT_APP_WEB_URL +
                    "/assets/images/home/banner_sports.webp"
                  }
                  alt=""
                />
              </Link>
            </a>
            <a
              className="entrance"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "SABA",
                    gameType: "VIRTUAL",
                    casinoType: "SABA-VIRTUAL-001",
                  });
                } else {
                  handleShow();
                }
              }}
            >
              {/* <dl className="entrance-title">
                <dt>Sport Book</dt>
                
              </dl> */}
              <img
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/sportbook.webp"
                }
                alt=""
              />
            </a>
            <a
              className=""
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "SPRIBE",
                    gameType: "EGAME",
                    casinoType: "SPRIBE-EGAME-001",
                  });
                } else {
                  handleShow();
                }
              }}
              neua="AEIndia Banner"
            >
              <dl className="entrance-title">
                <dt>Aviator</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
             <LazyLoadImage
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/aviator.webp"
                }
                alt=""
              />
            </a>
            <a
              className="entrance-half"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  
                  launchCasino({
                    platForm: "EVOLUTION",
                    gameType: "LIVE",
                    casinoType: "EVOLUTION-LIVE-006",
                    isLobbyTrue: true,
                  });
                } else {
                  handleShow();
                }
              }}
              neua="Casino Banner"
            >
              <dl className="entrance-title">
                <dt>Ezugi</dt>
                <dd><span>Play Now</span></dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/ezugi.webp"
                }
                alt=""
              />
            </a>
            <a
              className="entrance-half"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  
                  launchCasino({
                    platForm: "EVOLUTION",
                    gameType: "LIVE",
                    casinoType: "EVOLUTION-LIVE-006",
                    isLobbyTrue: true,
                  });
                } else {
                  handleShow();
                }
              }}
              neua="Casino Banner"
            >
              <dl className="entrance-title">
                <dt>EVOLUTION</dt>
                <dd><span>Play Now</span></dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/evolution.webp"
                }
                alt=""
              />
            </a>

            <a
              className="entrance"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "SABA",
                    gameType: "VIRTUAL",
                    casinoType: "SABA-VIRTUAL-001",
                  });
                } else {
                  handleShow();
                }
              }}
            >
              <dl className="entrance-title">
                <dt>Sport Book</dt>
                
              </dl>
              <img
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/sportbook2.webp"
                }
                alt=""
              />
            </a>
            <a
              className=""
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "KINGMAKER",
                    gameType: "TABLE",
                    casinoType: "KM-TABLE-060",
                  });
                } else {
                  handleShow();
                }
              }}
            >
              <dl className="entrance-title">
                <dt>Ludo Bet</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/ludo.webp"
                }
                alt=""
              />
            </a>
            <a
              className="entrance-half"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "SPRIBE",
                    gameType: "EGAME",
                    casinoType: "SPRIBE-EGAME-001",
                  });
                } else {
                  handleShow();
                }
              }}
              neua="AEIndia Banner"
            >
              <dl className="entrance-title">
                <dt>Jetx</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
             <LazyLoadImage
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/jetx.webp"
                }
                alt=""
              />
            </a>
            <a
              className="entrance-half"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "PT",
                    gameType: "",
                    casinoType: "",
                  });
                } else {
                  handleShow();
                }
              }}
              neua="AEIndia Banner"
            >
              <dl className="entrance-title">
                <dt>Playtech</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
             <LazyLoadImage
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/pt.webp"
                }
                alt=""
              />
            </a>

            <a
              className="entrance-half"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "BTG",
                    gameType: "SLOT",
                    casinoType: "BTG-SLOT-007",
                  });
                } else {
                  handleShow();
                }
              }}
              neua="AEIndia Banner"
            >
              <dl className="entrance-title">
                <dt>Millionaire Megaways</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
             <LazyLoadImage
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/BTG-SLOT-007.webp"
                }
                alt=""
              />
            </a>
            <a
              className="entrance-half"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "NETENT",
                    gameType: "SLOT",
                    casinoType: "NETENT-SLOT-080",
                  });
                } else {
                  handleShow();
                }
              }}
              neua="AEIndia Banner"
            >
              <dl className="entrance-title">
                <dt>Lost Relics™</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
             <LazyLoadImage
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/NETENT-SLOT-080.webp"
                }
                alt=""
              />
            </a>
            <a
              className="entrance-half"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "NLC",
                    gameType: "SLOT",
                    casinoType: "NLC-SLOT-023",
                  });
                } else {
                  handleShow();
                }
              }}
              neua="AEIndia Banner"
            >
              <dl className="entrance-title">
                <dt>The rave</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
             <LazyLoadImage
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/NLC-SLOT-023.webp"
                }
                alt=""
              />
            </a>
            <a
              className="entrance-half"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "PT",
                    gameType: "SLOT",
                    casinoType: "PT-SLOT-167",
                  });
                } else {
                  handleShow();
                }
              }}
              neua="AEIndia Banner"
            >
              <dl className="entrance-title">
                <dt>Fire Blaze: Red Wizard</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
             <LazyLoadImage
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/PT-SLOT-167.webp"
                }
                alt=""
              />
            </a>
            <a
              className="entrance-half"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  
                  launchCasino({
                    platForm: "RT",
                    gameType: "",
                    casinoType: "",
                  });
                } else {
                  handleShow();
                }
              }}
              neua="Casino Banner"
            >
              <dl className="entrance-title">
                <dt>Red Tiger</dt>
                <dd><span>Play Now</span></dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/redtigergaming.webp"
                }
                alt=""
              />
            </a>

            <a
              className="entrance-half"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  
                  launchCasino({
                    platForm: "EVOLUTION",
                    gameType: "LIVE",
                    casinoType: "EVOLUTION-LIVE-006",
                    
                  });
                } else {
                  handleShow();
                }
              }}
              neua="Casino Banner"
            >
              <dl className="entrance-title">
                <dt>Crazy Time</dt>
                <dd><span>Play Now</span></dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/Crazy Time.webp"
                }
                alt=""
              />
            </a>
            <a
              className="entrance-half"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  
                  launchCasino({
                    platForm: "PT",
                    gameType: "LIVE",
                    casinoType: "PT-LIVE-015",
                   
                  });
                } else {
                  handleShow();
                }
              }}
              neua="Casino Banner"
            >
              <dl className="entrance-title">
                <dt>Spin a Win</dt>
                <dd><span>Play Now</span></dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/PT-LIVE-015.webp"
                }
                alt=""
              />
            </a>
            
            <a
              className="entrance-half"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  
                  launchCasino({
                    platForm: "EVOLUTION",
                    gameType: "LIVE",
                    casinoType: "EVOLUTION-LIVE-016",
                   
                  });
                } else {
                  handleShow();
                }
              }}
              neua="Casino Banner"
            >
              <dl className="entrance-title">
                <dt>Power Blackjack</dt>
                <dd><span>Play Now</span></dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/Power Blackjack.webp"
                }
                alt=""
              />
            </a>
            <a
              className=""
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  
                  launchCasino({
                    platForm: "BG",
                    gameType: "",
                    casinoType: ""
                   
                  });
                } else {
                  handleShow();
                }
              }}
              neua="Casino Banner"
            >
              <dl className="entrance-title">
                <dt>betgame</dt>
                <dd><span>Play Now</span></dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/betgame.webp"
                }
                alt=""
              />
            </a>
            <a
              className=""
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "SEXYBCRT",
                    gameType: "LIVE",
                    casinoType: "MX-LIVE-002",
                    isLobbyTrue:true
                  });
                } else {
                  handleShow();
                }
              }}
              neua="AEIndia Banner"
            >
              <dl className="entrance-title">
                <dt>Ae Sexy</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/Sexy.webp"
                }
                alt=""
              />
            </a>

            <a
            
            className="entrance"
            href="javascript:void(0)"
            onClick={() => {
              if (!isEmpty(user)) {
                launchCasino({
                  platForm: "KINGMAKER",
                  gameType: "",
                  casinoType: "",
                });
              } else {
                handleShow();
              }
            }}
          >
            <dl className="entrance-title">
              <dt>KINGMAKER </dt>
              <dd><span>Play Now</span></dd>
            </dl>
            <img
           
              src={
                process.env.REACT_APP_WEB_URL +
                "/assets/images/home/kingmaker.webp"
              }
              alt=""
            />
          </a>
          <a
            
            className="entrance"
            href="javascript:void(0)"
            onClick={() => {
              if (!isEmpty(user)) {
                launchCasino({
                  platForm: "JILI",
                  gameType: "",
                  casinoType: "",
                });
              } else {
                handleShow();
              }
            }}
          >
            <dl className="entrance-title">
              <dt>JILI </dt>
              <dd><span>Play Now</span></dd>
            </dl>
            <img
           
              src={
                process.env.REACT_APP_WEB_URL +
                "/assets/images/home/JILI.webp"
              }
              alt=""
            />
          </a>
          <a
            
            className="entrance"
            href="javascript:void(0)"
            onClick={() => {
              if (!isEmpty(user)) {
                launchCasino({
                  platForm: "NETENT",
                  gameType: "",
                  casinoType: "",
                });
              } else {
                handleShow();
              }
            }}
          >
            <dl className="entrance-title">
              <dt>NETENT </dt>
              <dd><span>Play Now</span></dd>
            </dl>
            <img
           
              src={
                process.env.REACT_APP_WEB_URL +
                "/assets/images/home/netent.webp"
              }
              alt=""
            />
          </a>
          <a
              className="entrance-half"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  
                  launchCasino({
                    platForm: "EVOLUTION",
                    gameType: "LIVE",
                    casinoType: "EVOLUTION-LIVE-007",
                   
                  });
                } else {
                  handleShow();
                }
              }}
              neua="Casino Banner"
            >
              <dl className="entrance-title">
                <dt>Monopoly</dt>
                <dd><span>Play Now</span></dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/monopoly.webp"
                }
                alt=""
              />
            </a>
            <a
              className="entrance-half"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  
                  launchCasino({
                    platForm: "EVOLUTION",
                    gameType: "LIVE",
                    casinoType: "EVOLUTION-LIVE-183",
                   
                  });
                } else {
                  handleShow();
                }
              }}
              neua="Casino Banner"
            >
              <dl className="entrance-title">
                <dt>Funky Time</dt>
                <dd><span>Play Now</span></dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/funky time.webp"
                }
                alt=""
              />
            </a>

            <a
              className="entrance-half"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  
                  launchCasino({
                    platForm: "EVOLUTION",
                    gameType: "LIVE",
                    casinoType: "EVOLUTION-LIVE-225",
                   
                  });
                } else {
                  handleShow();
                }
              }}
              neua="Casino Banner"
            >
              <dl className="entrance-title">
                <dt>Deal Or No Deal</dt>
                <dd><span>Play Now</span></dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/Deal Or No Deal.webp"
                }
                alt=""
              />
            </a>
            <a
              className="entrance-half"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  
                  launchCasino({
                    platForm: "EVOLUTION",
                    gameType: "LIVE",
                    casinoType: "EVOLUTION-LIVE-011",
                   
                  });
                } else {
                  handleShow();
                }
              }}
              neua="Casino Banner"
            >
              <dl className="entrance-title">
                <dt>Lightning Dice</dt>
                <dd><span>Play Now</span></dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/Lightning Dice.webp"
                }
                alt=""
              />
            </a>
            <a
              className="entrance-half"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  
                  launchCasino({
                    platForm: "EVOLUTION",
                    gameType: "LIVE",
                    casinoType: "EVOLUTION-LIVE-035",
                   
                  });
                } else {
                  handleShow();
                }
              }}
              neua="Casino Banner"
            >
              <dl className="entrance-title">
                <dt>Dream Catcher</dt>
                <dd><span>Play Now</span></dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/Dream Catcher.webp"
                }
                alt=""
              />
            </a>

            <a
              className="entrance-half"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  
                  launchCasino({
                    platForm: "EVOLUTION",
                    gameType: "LIVE",
                    casinoType: "EVOLUTION-LIVE-018",
                   
                  });
                } else {
                  handleShow();
                }
              }}
              neua="Casino Banner"
            >
              <dl className="entrance-title">
                <dt>Monopoly Big Baller</dt>
                <dd><span>Play Now</span></dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/Monopoly Big Baller.webp"
                }
                alt=""
              />
            </a>

            <a
              className="entrance-half"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "KINGMAKER",
                    gameType: "TABLE",
                    casinoType: "KM-TABLE-036",
                  });
                } else {
                  handleShow();
                }
              }}
              href="javascript:void(0)"
              neua="Sicbo Banner"
            >
              <dl className="entrance-title">
                <dt>Coin Toss</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
             <LazyLoadImage
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/km-cointoss.webp"
                }
                alt=""
              />
            </a> 
            <a
              className="entrance-half"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "JILI",
                    gameType: "TABLE",
                    casinoType: "JILI-TABLE-011",
                  });
                } else {
                  handleShow();
                }
              }}
              href="javascript:void(0)"
              neua="Sicbo Banner"
            >
              <dl className="entrance-title">
                <dt>7up7down</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
             <LazyLoadImage
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/JILI-TABLE-011.webp"
                }
                alt=""
              />
            </a> 
            <a
              className="entrance-half"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "JILI",
                    gameType: "TABLE",
                    casinoType: "JILI-TABLE-003",
                  });
                } else {
                  handleShow();
                }
              }}
              href="javascript:void(0)"
              neua="Sicbo Banner"
            >
              <dl className="entrance-title">
                <dt>Andar Bahar</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
             <LazyLoadImage
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/JILI-TABLE-003.webp"
                }
                alt=""
              />
            </a> 


            <a
              className="entrance-half"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "JILI",
                    gameType: "TABLE",
                    casinoType: "JILI-TABLE-017",
                  });
                } else {
                  handleShow();
                }
              }}
              href="javascript:void(0)"
              neua="Sicbo Banner"
            >
              <dl className="entrance-title">
                <dt>Sicbo</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
             <LazyLoadImage
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/JILI-TABLE-017.webp"
                }
                alt=""
              />
            </a> 
            <a
              className="entrance-half"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "KINGMAKER",
                    gameType: "TABLE",
                    casinoType: "KM-TABLE-022",
                  });
                } else {
                  handleShow();
                }
              }}
              href="javascript:void(0)"
              neua="Sicbo Banner"
            >
              <dl className="entrance-title">
                <dt>Card Matka</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
             <LazyLoadImage
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/KM-TABLE-022.webp"
                }
                alt=""
              />
            </a> 

            <a
              className="entrance-half"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "KINGMAKER",
                    gameType: "TABLE",
                    casinoType: "KM-TABLE-021",
                  });
                } else {
                  handleShow();
                }
              }}
              href="javascript:void(0)"
              neua="Sicbo Banner"
            >
              <dl className="entrance-title">
                <dt>Number Matka</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
             <LazyLoadImage
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/KM-TABLE-021.webp"
                }
                alt=""
              />
            </a> 

            <a
              className=""
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "SV388",
                    gameType: "LIVE",
                    casinoType: "SV-LIVE-001",
                  });
                } else {
                  handleShow();
                }
              }}
              href="javascript:void(0)"
              neua="Sicbo Banner"
            >
              <dl className="entrance-title">
                <dt>COck Fighting</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
             <LazyLoadImage
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/SV-LIVE-001.webp"
                }
                alt=""
              />
            </a> 


            <a
              className="entrance-half"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "KINGMAKER",
                    gameType: "TABLE",
                    casinoType: "KM-TABLE-038",
                  });
                } else {
                  handleShow();
                }
              }}
              href="javascript:void(0)"
              neua="Sicbo Banner"
            >
              <dl className="entrance-title">
                <dt>Blackjack</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
             <LazyLoadImage
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/KM-TABLE-038.webp"
                }
                alt=""
              />
            </a> 
            <a
              className="entrance-half"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "KINGMAKER",
                    gameType: "TABLE",
                    casinoType: "KM-TABLE-042",
                  });
                } else {
                  handleShow();
                }
              }}
              href="javascript:void(0)"
              neua="Sicbo Banner"
            >
              <dl className="entrance-title">
                <dt>Minesweeper</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
             <LazyLoadImage
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/KM-TABLE-042.webp"
                }
                alt=""
              />
            </a> 
            <a
              className="entrance-half"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "E1SPORT",
                    gameType: "ESPORTS",
                    casinoType: "E1-ESPORTS-001",
                  });
                } else {
                  handleShow();
                }
              }}
              href="javascript:void(0)"
              neua="Sicbo Banner"
            >
              <dl className="entrance-title">
                <dt>E1sports</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
             <LazyLoadImage
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/E1-ESPORTS-001.webp"
                }
                alt=""
              />
            </a> 

            <a
              className="entrance-half"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "SABA",
                    gameType: "VIRTUAL",
                    casinoType: "SABA-VIRTUAL-001",
                  });
                } else {
                  handleShow();
                }
              }}
              href="javascript:void(0)"
              neua="Sicbo Banner"
            >
              <dl className="entrance-title">
                <dt>Saba Virtual</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
             <LazyLoadImage
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/SABA-VIRTUAL-001.webp"
                }
                alt=""
              />
            </a> 


            <a
              className=""
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: "HORSEBOOK",
                    gameType: "LIVE",
                    casinoType: "HRB-LIVE-001",
                  });
                } else {
                  handleShow();
                }
              }}
              href="javascript:void(0)"
              neua="Sicbo Banner"
            >
              <dl className="entrance-title">
                <dt>Horse racing</dt>
                <dd>
                  <span>Play Now</span>
                </dd>
              </dl>
             <LazyLoadImage
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/Horse Racing.webp"
                }
                alt=""
              />
            </a> 
            <a
              className="entrance-half"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  
                  launchCasino({
                    platForm: "EVOLUTION",
                    gameType: "LIVE",
                    casinoType: "EVOLUTION-LIVE-169",
                    isLobbyTrue: true,
                  });
                } else {
                  handleShow();
                }
              }}
              neua="Casino Banner"
            >
              <dl className="entrance-title">
                <dt>Imperial Quest</dt>
                <dd><span>Play Now</span></dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/Imperial Quest.webp"
                }
                alt=""
              />
            </a>
            <a
              className="entrance-half"
              href="javascript:void(0)"
              onClick={() => {
                if (!isEmpty(user)) {
                  
                  launchCasino({
                    platForm: "EVOLUTION",
                    gameType: "LIVE",
                    casinoType: "EVOLUTION-LIVE-046",
                    isLobbyTrue: true,
                  });
                } else {
                  handleShow();
                }
              }}
              neua="Casino Banner"
            >
              <dl className="entrance-title">
                <dt>Golden Wealth Baccarat</dt>
                <dd><span>Play Now</span></dd>
              </dl>
              <img
                src={
                  process.env.REACT_APP_WEB_URL +
                  "/assets/images/home/Golden Wealth Baccarat.webp"
                }
                alt=""
              />
            </a>






           
          </div>
           
          
        </Container>
        
      </div>
    </div>
  );
}

export default Home;
