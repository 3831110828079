import React, { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { pick } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "wc-toast";
import { apiGet, apiPost } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import { useReducer } from "react";
import moment from "moment";
import { useContext } from "react";
import AuthContext from "./AuthContext";
const BetSlipContext = createContext();

export default BetSlipContext;

export const BetSlipProvider = ({ children }) => {
  const showToggle = () => {
    document.getElementById("body-parent").classList.toggle("showbet");
  };
  let { user, loginUserDetail, loginUserDetailData, stakeData } =
    useContext(AuthContext);
  useContext(AuthContext);
  useEffect(() => {
    if (user != null) {
      loginUserDetail();
    }
  }, [user]);
  const location = useLocation();
  let sportType = location.pathname.replace("/", "");

  //All State
  let [loading, setLoading] = useState(false);
  const [selections, setSelections] = useState([]);

  const [priceList, setPriceList] = useState([
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
    { id: 4, value: 200 },
    { id: 5, value: 1000 },
    { id: 6, value: 5000 },
  ]);

  useEffect(() => {
    if (stakeData.length) {
      setPriceList(stakeData);
    }
  }, [user, stakeData.length]);

  const [minAmountCon, setMinAmountCond] = useState(false);
  const [backBetSlipArray, setBetBackSlipArray] = useState([]);
  const [layBetSlipArray, setLayBackSlipArray] = useState([]);
  const [backBetSlipValue, setBackBetSlipValue] = useState("");
  const [layBetSlipValue, setLayBetSlipValue] = useState("");
  const [details, setDetails] = useState({});
  const [showLogin, setShowLogin] = useState(false);
  const [allBetSlipData, setAllBetslipData] = useState([]);
  const [eventID, setEventId] = useState();
  const [selectedSelectins, setSelectedSelections] = useState([]);
  const [marketID, setMarketID] = useState("");
  const [disableBetFairPlaceBetBtn, setDisableBetFairPlaceBetBtn] =
    useState(true);
  const [loaderPlaceBetfair, setLoaderPlaceBetFair] = useState(false);
  const [activeOneClickAmount, setOneClickBetAmount] = useState(false);
  const [activeOneClick, setActiveOneClick] = useState(false);
  const [multiMarketEventId, setMultiMarketEventId] = useState(null);
  const [runApi, setRunApi] = useState(false);
  const [betFairLiability, setBetFairLiability] = useState(0);
  let handelShowBetSlip = (filter_odds, selection, betType, matchData) => {
    setEventId(matchData?.eventId);
    setMarketID(matchData?.marketId);
    setSelectedSelections(matchData?.runners);

    if (matchData?.matchSetting && matchData?.matchSetting.length) {
      var minAmount = matchData?.matchSetting.find(
        (el) => el.type == "betFaire"
      ).minAmount;
    }
    const filteredData = {
      ...filter_odds,
      price: priceList,
      selectionId: selection.SelectionId,
      teamName: selection.RunnerName,
      betType: betType,
      eventId: matchData?.eventId,
      marketId: matchData?.marketId,
      minAmount: minAmount ? minAmount : 10,
      runners: matchData?.runners,
      activePrice: loginUserDetailData?.defaultStake,
    };

    if (filter_odds?.ib == true) {
      const matchedRi = backBetSlipArray.find((el) => el.ri == filter_odds.ri);
      if (matchedRi) {
        const index = backBetSlipArray.findIndex((x) => x.ri == matchedRi.ri);

        backBetSlipArray.splice(index, 1, filteredData);
        setBetBackSlipArray((prevState) => [...prevState]);
      } else {
        setBetBackSlipArray([...backBetSlipArray, filteredData]);
      }
    } else {
      const matchedRi = layBetSlipArray.find((el) => el.ri == filter_odds.ri);
      if (matchedRi) {
        const index = layBetSlipArray.findIndex((x) => x.ri == matchedRi.ri);
        layBetSlipArray.splice(index, 1, filteredData);
        setLayBackSlipArray((prevState) => [...prevState]);
      } else {
        setLayBackSlipArray([...layBetSlipArray, filteredData]);
      }
    }
    showToggle();
  };

  let handelRemoveBackBetSlip = (index) => {
    const arr = [...backBetSlipArray];
    arr.splice(index, 1);
    setBetBackSlipArray(arr);
  };
  let handelRemoveLayBetSlip = (index) => {
    const arr = [...layBetSlipArray];
    arr.splice(index, 1);
    setLayBackSlipArray(arr);
  };

  const handelChangeBackAll = (event, index, data) => {
    setBackBetSlipValue(+event.target.value);

    const temp = backBetSlipArray;
    temp[index].activePrice = event.target.value ? +event.target.value : null;
    setBetBackSlipArray((prevState) => temp);
  };
  const handelChangeLayAll = (event, index, data) => {
    setLayBetSlipValue(+event.target.value);
    const temp = layBetSlipArray;
    temp[index].activePrice = event.target.value ? +event.target.value : null;
    setLayBackSlipArray((prevState) => temp);
  };

  const handelSetPrice = (item, data) => {
    const filteredData = {
      ...data,
      price: priceList,
      activePrice: item?.value,
      selectionId: data?.selectionId,
      teamName: data?.teamName,
      betType: data?.betType,
      eventId: data?.eventId,
      marketId: data?.marketId,
      runners: data?.runners,
    };

    if (data?.ib == true) {
      const matchedRi = backBetSlipArray.find((el) => el.ri == data.ri);

      if (matchedRi) {
        const index = backBetSlipArray.findIndex((x) => x.ri == matchedRi.ri);

        backBetSlipArray.splice(index, 1, filteredData);

        setBetBackSlipArray((prevState) => [...prevState]);
      }
    } else {
      const matchedRi = layBetSlipArray.find((el) => el.ri == data.ri);
      if (matchedRi) {
        const index = layBetSlipArray.findIndex((x) => x.ri == matchedRi.ri);
        layBetSlipArray.splice(index, 1, filteredData);
        setLayBackSlipArray((prevState) => [...prevState]);
      }
    }
  };

  const handelClearAllBet = () => {
    setBetBackSlipArray([]);
    setLayBackSlipArray([]);
  };
  const handelSetLiability = (slipArr) => {
    var original = 0;
    const arr = [...backBetSlipArray, ...layBetSlipArray];

    const Bet = arr?.map((it) => {
      original += it?.activePrice ? it?.activePrice : 0;
    });

    setBetFairLiability(original);
  };
  const [betLoader, setBetLoader] = useState(false);
  const [timeLeft, setTimeLeft] = useState(5);
  const handelPlaceBetfair = async (
    filter_Odds,
    selections,
    betType,
    matchData
  ) => {
    setTimeLeft(5);
    setBetLoader(true);
    setLoaderPlaceBetFair(true);
    const date = moment(new Date()).format("YYYY-MM-DD, h:mm:ss");
    const arr = [...backBetSlipArray, ...layBetSlipArray];

    const Bet = arr?.map((it) => {
      return {
        selectionId: it?.selectionId,
        teamName: it?.teamName,
        bhav: it?.rt || null,
        amount: it?.activePrice,
        betType: it?.betType,
        eventId: it?.eventId,
        marketId: it?.marketId,
      };
    });

    const result = activeOneClick
      ? {
          betPlaceTime: date,
          type: sportType,
          bet: [
            {
              selectionId: filter_Odds?.ri,
              teamName: selections?.runnerName,
              bhav: filter_Odds?.rt,
              amount: activeOneClickAmount,
              betType: betType,
              eventId: matchData?.eventId,
              marketId: matchData?.marketId,
            },
          ],
        }
      : {
          betPlaceTime: date,
          bet: Bet,
          type: sportType,
        };

    if (user) {
      if (activeOneClick) {
        if (!activeOneClickAmount) {
          toast.error("Please select one click bet amount");
          return false;
        }
      }
      const { status, data: response_users } = await apiPost(
        apiPath.multiEventPlaceBet,
        result
      );
      if (status === 200) {
        if (response_users.success) {
          toast.success(response_users.message);
          setLoaderPlaceBetFair(false);
          setBetLoader(false);
          setBetBackSlipArray([]);
          setLayBackSlipArray([]);
          setAllBetslipData([]);
        } else {
          toast.error(response_users.message);
          setLoaderPlaceBetFair(false);
          setBetLoader(false);
        }
      }
    } else {
      setShowLogin(true);
    }
  };

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
    }
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const disablePlaceBetFair = (Arr) => {
    if (Arr.some((el) => !el.activePrice)) {
      setDisableBetFairPlaceBetBtn(true);
      setMinAmountCond(false);
    } else if (Arr.some((el) => el.activePrice < el.minAmount)) {
      setMinAmountCond(true);
      setDisableBetFairPlaceBetBtn(true);
    } else {
      setMinAmountCond(false);
      setDisableBetFairPlaceBetBtn(false);
    }
  };

  useEffect(() => {
    setAllBetslipData([...backBetSlipArray, ...layBetSlipArray]);
    // handelBetFairPositions([...backBetSlipArray, ...layBetSlipArray]);
    handelSetLiability([...backBetSlipArray, ...layBetSlipArray]);
    disablePlaceBetFair([...backBetSlipArray, ...layBetSlipArray]);
  }, [backBetSlipArray, backBetSlipValue, layBetSlipArray, layBetSlipValue]);
  useEffect(() => {
    setBetBackSlipArray([]);
    setLayBackSlipArray([]);
    setAllBetslipData([]);
    // if (user != null) {
    //   myProfile();
    // }
  }, [user, location.pathname]);

  const handelAddMultiMarket = async (eventId) => {
    const result = {
      eventId: eventId,
    };

    if (user) {
      const { status, data: response_users } = await apiPost(
        apiPath.multiMarket,
        result
      );
      if (status === 200) {
        if (response_users.success) {
          //toast.success(response_users.message);
          if (response_users?.results?.eventId) {
            setMultiMarketEventId(response_users?.results?.eventId);
            setRunApi(!runApi);
          } else {
            setMultiMarketEventId(null);
            setRunApi(!runApi);
          }
        } else {
          //toast.error(response_users.message);
        }
      }
    } else {
      setShowLogin(true);
    }
  };

  let contextData = {
    handelShowBetSlip,
    handelRemoveBackBetSlip,
    handelRemoveLayBetSlip,
    handelChangeBackAll,
    handelChangeLayAll,
    handelSetPrice,
    handelClearAllBet,
    handelPlaceBetfair,
    selections,
    backBetSlipArray,
    layBetSlipArray,
    allBetSlipData,
    showLogin,
    setShowLogin,
    selectedSelectins,
    disableBetFairPlaceBetBtn,
    loaderPlaceBetfair,
    setActiveOneClick,
    activeOneClick,
    activeOneClickAmount,
    setOneClickBetAmount,
    handelAddMultiMarket,
    multiMarketEventId,
    runApi,
    betFairLiability,
    showToggle,
    minAmountCon,
    betLoader,
    timeLeft,
  };
  return (
    <BetSlipContext.Provider value={contextData}>
      {loading ? null : children}
    </BetSlipContext.Provider>
  );
};
