import React, { useState, useEffect, Fragment, useContext } from "react";
import { Accordion } from "react-bootstrap";
import _, { filter, pick } from "lodash";
import MatchSidebar from "../components/MatchSidebar";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { apiGet, apiPost } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import transparentGif from "../../src/assets/images/transparent.gif";
import moment from "moment/moment";
import { toast } from "wc-toast";
import AuthContext from "../context/AuthContext";
import Login from "../components/Login";
import helpers from "../utils/helpers";
import Loader from "../components/Loader";
import LoaderGif from "../assets/gif/loader.gif";
import BetSlipContext from "../context/BetSlipContext";
import loading40 from "../../src/assets/images/loading40.gif";
import io from "socket.io-client";
const isInputNumber = helpers.isInputNumber;
const MultiMarkets = () => {
  const showToggle = () => {
    document.getElementById("body-parent").classList.toggle("showbet");
  };
  let { activeOneClick, activeOneClickAmount, handelAddMultiMarket, runApi } =
    useContext(BetSlipContext);
  const [isLoader, setLoader] = useState(false);
  const params = useParams();
  const location = useLocation();
  var eventId = params.eventId;
  let sportType = params.sportType;
  let { user, loginUserDetail, loginUserDetailData, stakeData } =
    useContext(AuthContext);
  useContext(AuthContext);
  useEffect(() => {
    if (user != null) {
      loginUserDetail();
    }
  }, [user]);
  const navigate = useNavigate();
  //********** ALL STATE ******************************//
  const [minAmountCon, setMinAmountCond] = useState(false);
  const [fancy, setFancy] = useState(false);
  const [selections, setSelections] = useState([]);
  const [getEventId, setEventId] = useState([]);
  const [matchCentralizedIds, setMatchCentralizedIds] = useState({});
  const [backBetSlipArray, setBetBackSlipArray] = useState([]);
  const [layBetSlipArray, setLayBackSlipArray] = useState([]);
  const [priceList, setPriceList] = useState([
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
    { id: 4, value: 200 },
    { id: 5, value: 1000 },
    { id: 6, value: 5000 },
  ]);
  useEffect(() => {
    if (stakeData.length) {
      setPriceList(stakeData);
    }
  }, [user, stakeData.length]);

  const [backBetSlipValue, setBackBetSlipValue] = useState("");
  const [layBetSlipValue, setLayBetSlipValue] = useState("");
  const [details, setDetails] = useState([]);
  const [allBetSlipData, setAllBetslipData] = useState([]);
  const [showLogin, setShowLogin] = useState(false);
  const [disableBetFairPlaceBetBtn, setDisableBetFairPlaceBetBtn] =
    useState(true);
  const [loaderPlaceBetfair, setLoaderPlaceBetFair] = useState(false);
  const [tempSelection, setTempSelection] = useState([]);
  const [betFairLiabalitySelection, setLiabilitySelection] = useState([]);
  const [betFairLiability, setBetFairLiability] = useState(0);
  const [fancyList, setFancyList] = useState([]);
  // LEFT SIDEBAR CODE START
  const [tournamentData, setTournamentData] = useState("");
  const [matches, setMatches] = useState([]);
  const [matchesWithId, setMatchesWithId] = useState("");
  const [getSeriesId, setSeriesId] = useState("");
  const [getSeriesName, setSeriesName] = useState("");
  const [getMatchName, setMatchName] = useState("");
  const [getMatchId, setMatchId] = useState();
  const [getMarketId, setMarketId] = useState();
  const [getAllSports, setAllSports] = useState([]);
  const getTournamentData = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.seriesList + "?gameType=" + sportType
    );
    if (status === 200) {
      if (response_users.success) {
        setTournamentData(response_users.results);
      }
    }
  };
  const handelAll = async () => {
    const { status, data: response_users } = await apiGet(apiPath.sportsList);
    if (status === 200) {
      if (response_users.success) {
        setAllSports(response_users.results);
      }
    }
  };
  const getMatches = async (id) => {
    id && setSeriesId(id);
    const { status, data: response_users } = await apiGet(
      `${!id
        ? apiPath.matchList + "?gameType=" + sportType
        : apiPath.matchList + "?gameType=" + sportType + "&seriesId=" + id
      }`
    );
    if (status === 200) {
      if (response_users.success) {
        id
          ? setMatchesWithId(response_users.results)
          : setMatches(response_users.results);
      }
    }
  };
  const handelClickOddDetails = (matchId, marketId) => {
    navigate("/markets/" + sportType + "/" + matchId + "/" + marketId);
  };
  useEffect(() => {
    handelAll();
  }, []);
  useEffect(() => {
    getBetPosition(getEventId);
  }, [getEventId, !allBetSlipData.length]);
  const getMatchDetails = async () => {
    setLoader(true);
    const { status, data: response_users } = await apiGet(apiPath.multiMarket);
    if (status === 200) {
      if (response_users.success) {
        response_users?.results.map((data) => {
          data?.runners?.map((it) => {
            it.position = 0;
          });
        });

        response_users?.results.map((data) => {
          data.newRunners = JSON.parse(JSON.stringify(data.runners));
        });
        setDetails(response_users?.results);
        const marketIds = response_users.results.map((r) => r.centralizedId);

        if (marketIds && marketIds.length) {
          setMatchCentralizedIds(marketIds);
        }
        setLoader(false);
        var mainArr = [];
        const eventIds = response_users.results.map((r) =>
          mainArr.push({ eventId: r.eventId })
        );

        if (mainArr && mainArr.length) {
          setEventId(mainArr);
        }
        setLoader(false);
      }
    }
  };
  const getBetPosition = async (getEventId) => {
    let eventIds = {
      eventIds: getEventId,
    };

    const { status, data: response_users } = await apiPost(
      apiPath.betEventsBetPosition,
      eventIds
    );
    if (status === 200) {
      if (response_users.success) {
        if (details.length && response_users?.results.length) {
          response_users?.results.map((el) => {
            let findMatch = details.find((data) => data.eventId == el.eventId);
            if (details.some((rt) => rt.eventId == el.eventId)) {
              let arr = findMatch?.newRunners.map((item) => {
                let temp = el.betFair.map((rq) => {
                  if (item.SelectionId == rq.selectionId) {
                    item.position = rq.position;
                  }
                  return item;
                });
                return temp;
              });

              let temp = findMatch?.runners.map((item) => {
                let temp = el.betFair.map((rq) => {
                  if (item.SelectionId == rq.selectionId) {
                    item.position = rq.position;
                  }
                  return item;
                });
                return temp;
              });

              setDetails((prevState) => prevState);
            }
          });
        }
      }
    }
  };
  useEffect(() => {
    if (user != null) {
      getMatchDetails();
    }
  }, [user]);
  useEffect(() => {
    if (user) {
      getMatchDetails();
    }
  }, [runApi, user]);
  //*********** SOCKET CALL **************//
  useEffect(() => {
    // if (matchCentralizedIds.length) {
    //   const ACCESS_TOKEN =
    //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZ2VudGlkIjoiOWJldCIsImRhdGV0aW1lIjoxNjc5NDk2Mzg3OTkxLCJpYXQiOjE2Nzk0OTYzODd9.iVj6j-gOfLFxcuBhY6XXjlpUG1ZGv9YbAwZt_W2qsGg";
    //   // const newSocket = io(`wss://central3.satsport248.com:8881?token=${ACCESS_TOKEN}`, {
    //   //   transports: ["websocket"]
    //   // });

    //   const matchOddsSocket = new WebSocket(
    //     `wss://central3.satsport248.com:8881?token=${ACCESS_TOKEN}`
    //   );

    //   matchOddsSocket.onopen = (event) => {
    //     matchOddsSocket.send(
    //       JSON.stringify({
    //         action: "set",
    //         markets: `${matchCentralizedIds.join(",")}`,
    //       })
    //     );
    //   };
    //   matchOddsSocket.onmessage = (event) => {
    //     const socket_data = JSON.parse(event.data);
    //     for (const the_odd of socket_data.data) {
    //       setDetails((current) =>
    //         current.map((obj) => {
    //           if (obj.centralizedId == the_odd.mi) {
    //             if ("rt" in the_odd && the_odd?.rt.length > 0) {
    //               const back_odds = the_odd?.rt.filter((rt) => rt.ib) || [];
    //               const lay_odds = the_odd?.rt.filter((rt) => !rt.ib) || [];

    //               return { ...obj, back_odds: back_odds, lay_odds: lay_odds };
    //             }
    //           }
    //           return obj;
    //         })
    //       );
    //     }
    //   };
    // }
    const newSocket = io(
      `${process.env.REACT_APP_API_BASE_URL1}?token=${user?.user?._id ? user?.user?._id : 112233}&userType=front`,
      {
        transports: ["websocket"],
      }
    );
    const listenBetfairOdds = (event) => {
      const socket_data = event && event?.results || [];
      // console.log('socket_data----------------------', socket_data, socket_data.eventId, params?.eventId, socket_data.ms)
      if (socket_data.length > 0
      ) {

        for (const the_odd of socket_data) {
          setDetails((current) =>
            current.map((obj) => {
              //console.log("obj?.eventId, the_odd?.eventId", obj?.eventId, the_odd?.eventId);
              if (obj?.eventId && obj?.eventId == the_odd?.eventId) {
                const back_odds = the_odd?.rt.filter((rt) => rt.ib) || [];
                const lay_odds = the_odd?.rt.filter((rt) => !rt.ib) || [];
                return { ...obj, back_odds: back_odds, lay_odds: lay_odds };
              }
              return obj;
            })
          );
        }
      };

    };
    newSocket.on("listenBetFairOdds", listenBetfairOdds);
    return () => newSocket.close();
  }, [details]);
  //console.log("details", details);
  //***************** BET FAIR FUNCTIONS ***************************//

  useEffect(() => {
    setAllBetslipData([...backBetSlipArray, ...layBetSlipArray]);
    handelSetLiability([...backBetSlipArray, ...layBetSlipArray]);
    disablePlaceBetFair([...backBetSlipArray, ...layBetSlipArray]);
  }, [backBetSlipArray, backBetSlipValue, layBetSlipArray, layBetSlipValue]);

  const handelShowBetSlip = (
    filter_odds,
    selection,
    betType,
    runners,
    eventId,
    minAmount
  ) => {
    const filteredData = {
      ...filter_odds,
      price: priceList,
      selectionId: selection.SelectionId,
      teamName: selection.RunnerName,
      betType: betType,
      eventId: eventId,
      minAmount: minAmount ? minAmount : 10,
      runners: runners,
      activePrice: loginUserDetailData?.defaultStake,
    };

    if (filter_odds?.ib == true) {
      const matchedRi = backBetSlipArray.find((el) => el.ri == filter_odds.ri);
      if (matchedRi) {
        const index = backBetSlipArray.findIndex((x) => x.ri == matchedRi.ri);

        backBetSlipArray.splice(index, 1, filteredData);
        setBetBackSlipArray((prevState) => [...prevState]);
      } else {
        setBetBackSlipArray([...backBetSlipArray, filteredData]);
      }
    } else {
      const matchedRi = layBetSlipArray.find((el) => el.ri == filter_odds.ri);
      if (matchedRi) {
        const index = layBetSlipArray.findIndex((x) => x.ri == matchedRi.ri);
        layBetSlipArray.splice(index, 1, filteredData);
        setLayBackSlipArray((prevState) => [...prevState]);
      } else {
        setLayBackSlipArray([...layBetSlipArray, filteredData]);
      }
    }
    showToggle();
  };

  const handelBetFairPositions = (slipArr, index, eventId) => {
    let findMatch = details.find((el) => el.eventId == eventId);
    let newselection = findMatch?.newRunners?.map((data) => {
      let positionTemp = findMatch?.runners?.find(
        (el) => el.SelectionId == data.SelectionId
      )?.position;
      data.position = positionTemp ? positionTemp : 0;
      return data;
    });

    setDetails((prevState) => prevState);

    slipArr.map((data, ind) => {
      if (data?.activePrice >= 0) {
        if (data.ib) {
          let currentPosition = findMatch?.newRunners?.find(
            (rl) => rl.SelectionId == data.ri
          );
          let otherTeamPosition = findMatch?.newRunners?.find(
            (rl) => rl.SelectionId !== data.ri && rl.RunnerName != "The Draw"
          );

          let backProfit = (data.rt - 1) * data.activePrice;
          let backLoss = data.activePrice;
          if (currentPosition) {
            currentPosition.position = currentPosition.position + backProfit;
            otherTeamPosition.position = otherTeamPosition.position - backLoss;
            const result = findMatch?.newRunners?.map((data) => {
              if (data.SelectionId == currentPosition.SelectionId) {
                data.position = currentPosition.position
                  ? parseFloat(currentPosition.position.toFixed(2))
                  : 0;
              }
              if (data.SelectionId == otherTeamPosition.SelectionId) {
                data.position = otherTeamPosition.position
                  ? parseFloat(otherTeamPosition.position.toFixed(2))
                  : 0;
              }
              return data;
            });
            setDetails((prevState) => prevState);
            if (index == ind) {
              const temp = backBetSlipArray;
              temp?.map((item, idxx) => {
                if (index == idxx) {
                  item.profitLiability = parseInt(backProfit.toFixed(2));
                }
              });
              setBetBackSlipArray((prevState) => prevState);
            }
          }
        } else {
          let currentPosition = findMatch?.newRunners?.find(
            (rl) => rl.SelectionId == data.ri
          );
          let otherTeamPosition = findMatch?.newRunners?.find(
            (rl) => rl.SelectionId !== data.ri && rl.RunnerName != "The Draw"
          );

          let backProfit = data.activePrice;
          let backLoss = (data.rt - 1) * data.activePrice;
          if (currentPosition) {
            currentPosition.position = currentPosition.position - backLoss;
            otherTeamPosition.position =
              otherTeamPosition.position + backProfit;
            const result = findMatch?.newRunners?.map((data) => {
              if (data.SelectionId == currentPosition.SelectionId) {
                data.position = currentPosition.position
                  ? parseFloat(currentPosition.position.toFixed(2))
                  : 0;
              }
              if (data.SelectionId == otherTeamPosition.SelectionId) {
                data.position = otherTeamPosition.position
                  ? parseFloat(otherTeamPosition.position.toFixed(2))
                  : 0;
              }
              return data;
            });
            setBetBackSlipArray((prevState) => prevState);
            const temp = layBetSlipArray;
            temp?.map((item, idx) => {
              if (index == idx) {
                item.profitLiability = parseInt(backProfit.toFixed(2));
              }
            });
            setLayBackSlipArray((prevState) => prevState);
          }
        }
      }
    });
  };
  const handelSetLiability = (slipArr) => {
    var original = 0;
    let temp = details.map((rq) => {
      let arr = rq?.newRunners.map((item) => {
        if (item.position < 0) {
          original += item.position;
        }
      });

      setBetFairLiability(original);
    });
  };
  const handelSetPrice = (item, data) => {
    const filteredData = {
      ...data,
      price: priceList,
      activePrice: item?.value,
      selectionId: data?.selectionId,
      teamName: data?.teamName,
      betType: data?.betType,
      eventId: data.eventId,
      runners: data?.runners,
    };

    if (data?.ib == true) {
      const matchedRi = backBetSlipArray.find((el) => el.ri == data.ri);
      if (matchedRi) {
        const index = backBetSlipArray.findIndex((x) => x.ri == matchedRi.ri);

        backBetSlipArray.splice(index, 1, filteredData);
        setBetBackSlipArray((prevState) => [...prevState]);
        handelBetFairPositions(
          [...backBetSlipArray, ...layBetSlipArray],
          index,
          data?.eventId
        );
      }
    } else {
      const matchedRi = layBetSlipArray.find((el) => el.ri == data.ri);
      if (matchedRi) {
        const index = layBetSlipArray.findIndex((x) => x.ri == matchedRi.ri);

        layBetSlipArray.splice(index, 1, filteredData);
        setLayBackSlipArray((prevState) => [...prevState]);
        handelBetFairPositions(
          [...backBetSlipArray, ...layBetSlipArray],
          index,
          data?.eventId
        );
      }
    }
  };
  const handelChangeBackAll = (event, index, data) => {
    setBackBetSlipValue(+event.target.value);
    const temp = backBetSlipArray;
    temp[index].activePrice = event.target.value ? +event.target.value : null;
    setBetBackSlipArray((prevState) => temp);
    handelBetFairPositions(
      [...backBetSlipArray, ...layBetSlipArray],
      index,
      data?.eventId
    );
  };
  const handelChangeLayAll = (event, index, data) => {
    setLayBetSlipValue(+event.target.value);
    const temp = layBetSlipArray;
    temp[index].activePrice = event.target.value ? +event.target.value : null;
    setLayBackSlipArray((prevState) => temp);
    handelBetFairPositions(
      [...backBetSlipArray, ...layBetSlipArray],
      index,
      data?.eventId
    );
  };
  const handelRemoveBackBetSlip = (index, eventId) => {
    const arr = [...backBetSlipArray];
    arr.splice(index, 1);
    setBetBackSlipArray(arr);
    handelBetFairPositions([...arr, ...layBetSlipArray], index, eventId);
  };
  const handelRemoveLayBetSlip = (index, eventId) => {
    const arr = [...layBetSlipArray];
    arr.splice(index, 1);
    setLayBackSlipArray(arr);
    handelBetFairPositions([...backBetSlipArray, ...arr], index, eventId);
  };
  const [betLoader, setBetLoader] = useState(false);
  const [timeLeft, setTimeLeft] = useState(5);
  const handelPlaceBetfair = async (
    filter_Odds,
    selections,
    betType,
    eventId,
    marketId
  ) => {
    const date = moment(new Date()).format("YYYY-MM-DD, h:mm:ss");
    const arr = [...backBetSlipArray, ...layBetSlipArray];
    const Bet = arr?.map((it) => {
      return {
        selectionId: it?.selectionId,
        teamName: it?.teamName,
        bhav: it?.rt || null,
        amount: it?.activePrice,
        betType: it?.betType,
        eventId: it?.eventId,
        marketId: it?.marketId,
      };
    });
    if (user) {
      if (activeOneClick) {
        if (!activeOneClickAmount) {
          //toast.error("Please select one click bet amount");
          return false;
        }
      }
      setTimeLeft(5);
      setBetLoader(true);
      setLoaderPlaceBetFair(true);
      const result = activeOneClick
        ? {
          betPlaceTime: date,
          type: sportType,
          bet: [
            {
              selectionId: filter_Odds?.ri,
              teamName: selections?.runnerName,
              bhav: filter_Odds?.rt,
              amount: activeOneClickAmount,
              betType: betType,
              eventId: eventId,
              marketId: marketId,
            },
          ],
        }
        : {
          betPlaceTime: date,
          bet: Bet,
          type: "cricket",
        };
      const { data: response_users2 } = await apiPost(
        apiPath.beforeBetPlace,
        result
      );
      if (response_users2.success) {
        const { status, data: response_users } = await apiPost(
          apiPath.multiEventPlaceBet,
          result
        );
        if (status === 200) {
          if (response_users.success) {
            setLoaderPlaceBetFair(false);
            setBetLoader(false);
            toast.success(response_users.message);
            getBetPosition();
            setBetBackSlipArray([]);
            setLayBackSlipArray([]);
            setAllBetslipData([]);
          } else {
            setLoaderPlaceBetFair(false);
            setBetLoader(false);
            toast.error(response_users.message);
          }
        }
      } else {
        setLoaderPlaceBetFair(false);
        setBetLoader(false);
        toast.error(response_users2.message);
      }
    } else {
      setShowLogin(true);
    }
  };
  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
    }
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);
  const disablePlaceBetFair = (Arr) => {
    if (Arr.some((el) => !el.activePrice)) {
      setDisableBetFairPlaceBetBtn(true);
      setMinAmountCond(false);
    } else if (Arr.some((el) => el.activePrice < el.minAmount)) {
      setMinAmountCond(true);
      setDisableBetFairPlaceBetBtn(true);
    } else {
      setMinAmountCond(false);
      setDisableBetFairPlaceBetBtn(false);
    }
  };
  const handelClearAllBet = () => {
    setBetBackSlipArray([]);
    setLayBackSlipArray([]);
  };

  //******************************* BET FAIR UI ****************************************************//

  //****** BET FAIR BACK DATA ******//
  const renderBackOdds = (
    eventId,
    selection,
    back_odds,
    runners,
    marketId,
    matchSetting,
    matchOdds,
    matchStatus,
    gameType
  ) => {
    if (matchSetting && matchSetting.length) {
      var minAmount = matchSetting.find(
        (el) => el.type == "betFaire"
      ).minAmount;
    }

    const the_odds = back_odds;
    // console.log("back_odds----------->", back_odds);
    // console.log("selection--------->", selection);
    const filter_odds = the_odds.filter(
      (todd) => todd.ri === selection.SelectionId
    );

    return (
      <>
        <td
          value={filter_odds[2]?.rt}
          id={"back_odds_" + filter_odds[2]?.rt}
          className={`back-3 bet-cursor mobile_hide ${document.getElementById("back_odds_" + filter_odds[2]?.rt)?.getAttribute('value') !=
            filter_odds[2]?.rt && filter_odds[2]?.rt && "spark"
            }`
          }
          onClick={() =>
            filter_odds[2]?.rt == 0 ||
              filter_odds.length === 0 ||
              selection?.RunnerName == "The Draw" ||
              matchOdds == "off" ||
              matchStatus !== "in_play" ||
              matchSetting?.find((res) => res?.type === "betFaire")?.oddsLimit <=
              filter_odds[2]?.rt ||
              loginUserDetailData?.matchSetting?.find(
                (res) => res?.type === "betFaire" && res?.sportType === gameType
              )?.oddsLimit <= filter_odds[2]?.rt
              ? null
              : activeOneClick
                ? handelPlaceBetfair(
                  filter_odds[2],
                  selection,
                  "back",
                  eventId,
                  marketId
                )
                : handelShowBetSlip(
                  filter_odds[2],
                  selection,
                  "back",
                  runners,
                  eventId,
                  minAmount
                )
          }
          style={
            filter_odds[2]?.rt == 0 ||
              filter_odds.length === 0 ||
              selection?.RunnerName == "The Draw" ||
              matchOdds == "off" ||
              matchStatus !== "in_play" ||
              matchSetting?.find((res) => res?.type === "betFaire")?.oddsLimit <=
              filter_odds[2]?.rt ||
              loginUserDetailData?.matchSetting?.find(
                (res) => res?.type === "betFaire" && res?.sportType === gameType
              )?.oddsLimit <= filter_odds[2]?.rt
              ? { cursor: "not-allowed" }
              : { cursor: "pointer" }
          }
        >
          <a>
            {filter_odds[2]?.rt || null}
            <span>{filter_odds[2]?.rt > 0 && filter_odds[2]?.bv}</span>
          </a>
        </td>
        <td
          value={filter_odds[1]?.rt}
          id={"back_odds_" + filter_odds[1]?.rt}
          className={`back-2 bet-cursor mobile_hide ${document.getElementById("back_odds_" + filter_odds[1]?.rt)?.getAttribute('value') !=
            filter_odds[1]?.rt && filter_odds[1]?.rt && "spark"
            }`
          }

          onClick={() =>
            filter_odds[1]?.rt == 0 ||
              filter_odds.length === 0 ||
              selection?.RunnerName == "The Draw" ||
              matchOdds == "off" ||
              matchStatus !== "in_play" ||
              matchSetting?.find((res) => res?.type === "betFaire")?.oddsLimit <=
              filter_odds[1]?.rt ||
              loginUserDetailData?.matchSetting?.find(
                (res) => res?.type === "betFaire" && res?.sportType === gameType
              )?.oddsLimit <= filter_odds[1]?.rt
              ? null
              : activeOneClick
                ? handelPlaceBetfair(
                  filter_odds[1],
                  selection,
                  "back",
                  eventId,
                  marketId
                )
                : handelShowBetSlip(
                  filter_odds[1],
                  selection,
                  "back",
                  runners,
                  eventId,
                  minAmount
                )
          }
          style={
            filter_odds[1]?.rt == 0 ||
              filter_odds.length === 0 ||
              selection?.RunnerName == "The Draw" ||
              matchOdds == "off" ||
              matchStatus !== "in_play" ||
              matchSetting?.find((res) => res?.type === "betFaire")?.oddsLimit <=
              filter_odds[1]?.rt ||
              loginUserDetailData?.matchSetting?.find(
                (res) => res?.type === "betFaire" && res?.sportType === gameType
              )?.oddsLimit <= filter_odds[1]?.rt
              ? { cursor: "not-allowed" }
              : { cursor: "pointer" }
          }
        >
          <a>
            {filter_odds[1]?.rt || null}
            <span>{filter_odds[1]?.rt > 0 && filter_odds[1]?.bv}</span>
          </a>
        </td>
        <td
          value={filter_odds[0]?.rt}
          id={"back_odds_" + filter_odds[0]?.rt}
          className={`back-1s bet-cursor ${document.getElementById("back_odds_" + filter_odds[0]?.rt)?.getAttribute('value') !=
            filter_odds[0]?.rt && filter_odds[0]?.rt && "spark"
            }`
          }

          onClick={() =>
            filter_odds[0]?.rt == 0 ||
              filter_odds.length === 0 ||
              selection?.RunnerName == "The Draw" ||
              matchOdds == "off" ||
              matchStatus !== "in_play" ||
              matchSetting?.find((res) => res?.type === "betFaire")?.oddsLimit <=
              filter_odds[0]?.rt ||
              loginUserDetailData?.matchSetting?.find(
                (res) => res?.type === "betFaire" && res?.sportType === gameType
              )?.oddsLimit <= filter_odds[0]?.rt
              ? null
              : activeOneClick
                ? handelPlaceBetfair(
                  filter_odds[0],
                  selection,
                  "back",
                  eventId,
                  marketId
                )
                : handelShowBetSlip(
                  filter_odds[0],
                  selection,
                  "back",
                  runners,
                  eventId,
                  minAmount
                )
          }
          style={
            filter_odds[0]?.rt == 0 ||
              filter_odds.length === 0 ||
              selection?.RunnerName == "The Draw" ||
              matchOdds == "off" ||
              matchStatus !== "in_play" ||
              matchSetting?.find((res) => res?.type === "betFaire")?.oddsLimit <=
              filter_odds[0]?.rt ||
              loginUserDetailData?.matchSetting?.find(
                (res) => res?.type === "betFaire" && res?.sportType === gameType
              )?.oddsLimit <= filter_odds[0]?.rt
              ? { cursor: "not-allowed" }
              : { cursor: "pointer" }
          }
        >
          <a>
            {filter_odds[0]?.rt || null}
            <span>{filter_odds[0]?.rt > 0 && filter_odds[0]?.bv}</span>
          </a>
        </td>
      </>
    );
  };
  //****** BET FAIR LAY DATA ******//
  const renderLayOdds = (
    eventId,
    selection,
    lay_odds,
    runners,
    marketId,
    matchSetting,
    matchOdds,
    matchStatus,
    gameType
  ) => {
    const the_odds = lay_odds;
    const filter_odds = the_odds.filter(
      (todd) => todd.ri === selection.SelectionId
    );
    // .sort((a, b) => {
    //   return a - b;
    // });

    if (matchSetting && matchSetting.length) {
      var minAmount = matchSetting.find(
        (el) => el.type == "betFaire"
      ).minAmount;
    }
    return (
      <>
        <td

          value={filter_odds[0]?.rt}
          id={"lay_odds_" + filter_odds[0]?.rt}
          className={`lay-1 bet-cursor ${document.getElementById("lay_odds_" + filter_odds[0]?.rt)?.getAttribute('value') !=
            filter_odds[0]?.rt && filter_odds[0]?.rt && "spark"
            }`
          }

          onClick={() =>
            filter_odds.length === 0 ||
              selection?.RunnerName == "The Draw" ||
              matchOdds == "off" ||
              matchStatus !== "in_play" ||
              matchSetting?.find((res) => res?.type === "betFaire")?.oddsLimit <=
              filter_odds[0]?.rt ||
              loginUserDetailData?.matchSetting?.find(
                (res) => res?.type === "betFaire" && res?.sportType === gameType
              )?.oddsLimit <= filter_odds[0]?.rt
              ? null
              : activeOneClick
                ? handelPlaceBetfair(
                  filter_odds[0],
                  selection,
                  "lay",
                  eventId,
                  marketId
                )
                : handelShowBetSlip(
                  filter_odds[0],
                  selection,
                  "lay",
                  runners,
                  eventId,
                  minAmount
                )
          }
          style={
            filter_odds.length === 0 ||
              selection?.RunnerName == "The Draw" ||
              matchOdds == "off" ||
              matchStatus !== "in_play" ||
              matchSetting?.find((res) => res?.type === "betFaire")?.oddsLimit <=
              filter_odds[0]?.rt ||
              loginUserDetailData?.matchSetting?.find(
                (res) => res?.type === "betFaire" && res?.sportType === gameType
              )?.oddsLimit <= filter_odds[0]?.rt
              ? { cursor: "not-allowed" }
              : { cursor: "pointer" }
          }
        >
          <a>
            {filter_odds[0]?.rt || null}
            <span>{filter_odds[0]?.bv}</span>
          </a>
        </td>
        <td value={filter_odds[1]?.rt}
          id={"lay_odds_" + filter_odds[1]?.rt}
          className={`lay-2 bet-cursor mobile_hide  ${document.getElementById("lay_odds_" + filter_odds[1]?.rt)?.getAttribute('value') !=
            filter_odds[1]?.rt && filter_odds[1]?.rt && "spark"
            }`
          }

          onClick={() =>
            filter_odds.length === 0 ||
              selection?.RunnerName == "The Draw" ||
              matchOdds == "off" ||
              matchStatus !== "in_play" ||
              matchSetting?.find((res) => res?.type === "betFaire")?.oddsLimit <=
              filter_odds[1]?.rt ||
              loginUserDetailData?.matchSetting?.find(
                (res) => res?.type === "betFaire" && res?.sportType === gameType
              )?.oddsLimit <= filter_odds[1]?.rt
              ? null
              : activeOneClick
                ? handelPlaceBetfair(
                  filter_odds[1],
                  selection,
                  "lay",
                  eventId,
                  marketId
                )
                : handelShowBetSlip(
                  filter_odds[1],
                  selection,
                  "lay",
                  runners,
                  eventId,
                  minAmount
                )
          }
          style={
            filter_odds.length === 0 ||
              selection?.RunnerName == "The Draw" ||
              matchOdds == "off" ||
              matchStatus !== "in_play" ||
              matchSetting?.find((res) => res?.type === "betFaire")?.oddsLimit <=
              filter_odds[1]?.rt ||
              loginUserDetailData?.matchSetting?.find(
                (res) => res?.type === "betFaire" && res?.sportType === gameType
              )?.oddsLimit <= filter_odds[1]?.rt
              ? { cursor: "not-allowed" }
              : { cursor: "pointer" }
          }
        >
          <a>
            {filter_odds[1]?.rt || null}
            <span>{filter_odds[1]?.bv}</span>
          </a>
        </td>
        <td
          value={filter_odds[2]?.rt}
          id={"lay_odds_" + filter_odds[2]?.rt}
          className={`lay-3 bet-cursor mobile_hide ${document.getElementById("lay_odds_" + filter_odds[2]?.rt)?.getAttribute('value') !=
            filter_odds[2]?.rt && filter_odds[2]?.rt && "spark"
            }`
          }
          onClick={() =>
            filter_odds.length === 0 ||
              selection?.RunnerName == "The Draw" ||
              matchOdds == "off" ||
              matchStatus !== "in_play" ||
              matchSetting?.find((res) => res?.type === "betFaire")?.oddsLimit <=
              filter_odds[2]?.rt ||
              loginUserDetailData?.matchSetting?.find(
                (res) => res?.type === "betFaire" && res?.sportType === gameType
              )?.oddsLimit <= filter_odds[2]?.rt
              ? null
              : activeOneClick
                ? handelPlaceBetfair(
                  filter_odds[2],
                  selection,
                  "lay",
                  eventId,
                  marketId
                )
                : handelShowBetSlip(
                  filter_odds[2],
                  selection,
                  "lay",
                  runners,
                  eventId,
                  minAmount
                )
          }
          style={
            filter_odds.length === 0 ||
              selection?.RunnerName == "The Draw" ||
              matchOdds == "off" ||
              matchStatus !== "in_play" ||
              matchSetting?.find((res) => res?.type === "betFaire")?.oddsLimit <=
              filter_odds[2]?.rt ||
              loginUserDetailData?.matchSetting?.find(
                (res) => res?.type === "betFaire" && res?.sportType === gameType
              )?.oddsLimit <= filter_odds[2]?.rt
              ? { cursor: "not-allowed" }
              : { cursor: "pointer" }
          }
        >
          <a>
            {filter_odds[2]?.rt || null}
            <span>{filter_odds[2]?.bv}</span>
          </a>
        </td>
      </>
    );
  };
  //****** BET FAIR BACK (Blue) BET SLIP CARDS ******//
  const renderBackBetSlip = (backBetSlip) => {
    return (
      <>
        {backBetSlip.length !== 0 && (
          <ul className="slip-head d-flex">
            <li className="col-bet">Back (Bet For)</li>
            <li id="oddsHeader" className="col-odd">
              Odds
            </li>
            <li id="runsHeader" className="col-odd">
              Unit:Runs
            </li>
            <li className="col-stake">Stake</li>
            <li className="col-profit text-end">Profit</li>
          </ul>
        )}

        {backBetSlip &&
          backBetSlip.length !== 0 &&
          backBetSlip.map((data, index) => {
            var minimumAmount = data?.minAmount ? data?.minAmount : 10;
            return (
              <div className="team_list_wrapper blue_bg_tuch" key={index}>
                <h4>
                  <img
                    className="icon-in_play"
                    src={transparentGif}
                    alt="No_Image"
                  />{" "}
                  {data?.runners[0]?.RunnerName} v&nbsp;
                  {data?.runners[1]?.RunnerName}
                </h4>
                <div className="match_oddds">
                  <span className="d-flex align-center justify-content-center">
                    <i
                      className="fa fa-window-close bet-slip-cross-icon"
                      onClick={() =>
                        handelRemoveBackBetSlip(index, data.eventId)
                      }
                    ></i>
                    <h5>
                      {data?.teamName}
                      <small>Match Odds</small>
                    </h5>
                  </span>
                  <div className="up_down_rate">
                    <ul>
                      <li>
                        <input
                          type="text"
                          value={data?.rt}
                          disabled
                          className="bet-slip-first-input"
                        />
                      </li>
                      <li>
                        <input
                          type="number"
                          value={data?.activePrice ? data?.activePrice : ""}
                          name="back-tik"
                          onChange={(e) => handelChangeBackAll(e, index, data)}
                          onKeyPress={(e) => {
                            isInputNumber(e);
                            if (e.target.value.length > 6) {
                              e.preventDefault();
                            }
                          }}
                          max={1500000}
                          style={
                            !data?.activePrice
                              ? {
                                border: "1px solid red",
                                cursor: "pointer",
                                borderRadius: "4px",
                              }
                              : {}
                          }
                          title={!data?.activePrice && "Enter amount"}
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="odds_value">{`${data?.profitLiability ? "$" + data?.profitLiability : ""
                    }`}</div>
                </div>

                <div className="preset_value">
                  <ul>
                    {data?.price.map((money) => {
                      return (
                        <li key={money?.id}>
                          <button
                            className="price-button-pay-slip"
                            onClick={() => handelSetPrice(money, data)}
                            style={
                              data.activePrice === money.value
                                ? {
                                  background: "#444444",
                                  color: "#f8d61c ",
                                  cursor: "not-allowed",
                                }
                                : {}
                            }
                            disabled={data.activePrice === money.value}
                          >
                            {money?.value}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="min-bets">Min bet:{minimumAmount}</div>
              </div>
            );
          })}
      </>
    );
  };
  //****** BET FAIR LAY (Pink) BET SLIP CARDS ******//
  const renderLayBetSlip = () => {
    return (
      <>
        {layBetSlipArray.length !== 0 && (
          <ul className="slip-head d-flex">
            <li className="col-bet">Lay (Bet Against)</li>
            <li id="oddsHeader" className="col-odd">
              Odds
            </li>
            <li id="runsHeader" className="col-odd">
              Stake
            </li>
            <li className="col-stake">Liability</li>
          </ul>
        )}
        {layBetSlipArray &&
          layBetSlipArray.length !== 0 &&
          layBetSlipArray.map((item, index) => {
            return (
              <div className="team_list_wrapper lay_bg_tuch" key={index}>
                <h4>
                  <img
                    className="icon-in_play"
                    src={transparentGif}
                    alt="No_Image"
                  />{" "}
                  {item?.runners[0]?.RunnerName} v&nbsp;
                  {item?.runners[1]?.RunnerName}
                </h4>
                <div className="match_oddds">
                  <span className="d-flex align-center justify-content-center">
                    <i
                      className="fa fa-window-close bet-slip-cross-icon"
                      onClick={() =>
                        handelRemoveLayBetSlip(index, item.eventId)
                      }
                    ></i>
                    <h5>
                      {item?.teamName}
                      <small>Match Odds</small>
                    </h5>
                  </span>
                  <div className="up_down_rate">
                    <ul>
                      <li className="bill-slip-rt">
                        <input
                          type="text"
                          value={item?.rt}
                          disabled
                          className="bet-slip-first-input"
                        />{" "}
                      </li>
                      <li>
                        <input
                          type="number"
                          value={item?.activePrice ? item?.activePrice : ""}
                          onChange={(e) => handelChangeLayAll(e, index, item)}
                          onKeyPress={(e) => {
                            isInputNumber(e);
                            if (e.target.value.length > 6) {
                              e.preventDefault();
                            }
                          }}
                          max={1500000}
                          style={
                            !item?.activePrice
                              ? { border: "1px solid red", borderRadius: "4px" }
                              : {}
                          }
                          title={!item?.activePrice && "Enter amount"}
                        />{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="odds_value">
                    {`${item?.profitLiability ? "$" + item?.profitLiability : ""
                      }`}{" "}
                  </div>
                </div>

                <div className="preset_value">
                  <ul>
                    {item?.price.map((money) => {
                      return (
                        <li key={money?.id}>
                          <button
                            className="price-button-pay-slip"
                            onClick={() => handelSetPrice(money, item)}
                            style={
                              item.activePrice === money.value
                                ? {
                                  background: "#444444",
                                  color: "#f8d61c ",
                                  cursor: "not-allowed",
                                }
                                : {}
                            }
                            disabled={item.activePrice === money.value}
                          >
                            {money?.value}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="min-bets">Min bet:10</div>
              </div>
            );
          })}
      </>
    );
  };
  useEffect(() => {
    if (details.length > 0) {
      setLoader(false);
    }
  }, [details.length]);

  return (
    <div>
      <div className="full-wrap">
        {/* LEFT SIDEBAR  */}
        <MatchSidebar
          matchType={sportType}
          tournamentData={tournamentData}
          handelBackFunc={(id) => {
            setSeriesId();
            setSeriesName();
            getTournamentData();
          }}
          handelClickFunc={(id) => {
            getMatches(id);
            setAllSports();
            setTournamentData();
            setMatchName();
          }}
          matchesWithId={matchesWithId}
          setSeriesName={(seriesName) => {
            setSeriesName(seriesName);
            setMatchName();
          }}
          setMatchName={(matchName) => {
            setMatchName(matchName);
            setMatchesWithId();
          }}
          setMatchId={(matchId) => {
            setMatchId(matchId);
            setMatchesWithId();
          }}
          setMarketId={(marketId) => {
            setMarketId(marketId);
          }}
          seriesName={getSeriesName}
          matchName={getMatchName}
          matchId={getMatchId}
          marketId={getMarketId}
          getSeriesId={getSeriesId}
          handelClickOddDetails={(matchId, marketId) =>
            handelClickOddDetails(matchId, marketId)
          }
          handelAll={() => {
            handelAll();
            setSeriesId();
            setMatchName();
          }}
          AllSports={getAllSports}
          handelSportFunc={(id) => {
            getMatches(id);
            setAllSports();
            getTournamentData();
          }}
        />
        {/* LEFT SIDEBAR  */}

        <div className="col-center games_center_block game-wrapper-block">
          <div className="multi-mark-h">
            <strong>Multi Markets</strong>
          </div>
          {isLoader ? (
            <span className="d-flex justify-content-center align-item-center mt-2 pb-3">
              <img src={LoaderGif} alt="No_Image" />
            </span>
          ) : details.length > 0 ? (
            <div className="match-odds-table game_table_main_wrapper">
              {details.length > 0 &&
                details.map((matchData, index) => {
                  return (
                    <>
                      {" "}
                      <div className="space-bet mb-3">
                        <div className="special_bet mb-3">
                          <h3 className="mb-0 d-flex align-items-center">
                            <button
                              className="add-pin"
                              onClick={() =>
                                handelAddMultiMarket(matchData?.eventId)
                              }
                              title="Add to Multi Markets"
                            ></button>
                            <Link
                              to={`/markets/${matchData?.gameType}/${matchData?.eventId}/${matchData?.marketId}`}
                            >
                              {matchData?.runners[0]?.RunnerName} v{" "}
                              {matchData?.runners[1]?.RunnerName}
                              <p>Match Odds</p>
                            </Link>
                            <strong class="sportstrong"></strong>
                          </h3>

                          <dl className="fancy-info">
                            <button
                              className="multi_refresh"
                              onClick={() => getMatchDetails()}
                            ></button>
                          </dl>
                        </div>

                        <table className="bets game_table_inner">
                          <tbody>
                            <tr className="bet-all" key={index + 1}>
                              <td className="text-start">
                                {matchData?.runners?.length} selections
                              </td>
                              <td
                                id="backPercent"
                                className="refer-bet mobile_hide"
                                colSpan="2"
                              >
                                100.6%
                              </td>
                              <td className="back-all_td">
                                <a id="backAll" className="back-all">
                                  <i className="backall-shape"></i>
                                  <span>Back all</span>
                                </a>
                              </td>
                              <td className="lay-all_td">
                                <a id="layAll" className="lay-all">
                                  <i className="layall-shape"></i>
                                  <span>Lay all</span>
                                </a>
                              </td>
                              <td
                                id="layPercent"
                                className="refer-book mobile_hide"
                                colSpan="2"
                              >
                                99.9%
                              </td>
                            </tr>
                            {/* {console.log(" matchData?.newRunners matchData?.back_odds", matchData?.newRunners, matchData?.back_odds)} */}
                            {matchData?.back_odds &&
                              matchData?.newRunners.map((selection, index) => {
                                return (
                                  <tr key={index + 1}>
                                    <th className="">
                                      <p>
                                        <a href="#">
                                          <img
                                            className="icon-predict"
                                            src="/images/transparent.gif"
                                          />
                                        </a>
                                        {selection?.RunnerName}
                                        <p
                                          style={
                                            selection.position <= 0
                                              ? { color: "red" }
                                              : { color: "green" }
                                          }
                                        >
                                          {Math.abs(selection.position) != 0 &&
                                            !isNaN(
                                              Math.abs(selection.position)
                                            ) && (
                                              <>
                                                <i className="fa fa-arrow-right"></i>
                                                &nbsp;&nbsp;
                                                {parseFloat(
                                                  Math.abs(selection.position)
                                                ).toFixed(2)}
                                              </>
                                            )}
                                        </p>
                                      </p>
                                      <span id="" className="lose d-none">
                                        {" "}
                                        0.00
                                      </span>
                                      <span id="" className="win d-none"></span>
                                      <span id="" className="to-lose d-none">
                                        {" "}
                                        0.00
                                      </span>
                                    </th>
                                    {matchData?.back_odds?.length &&
                                      renderBackOdds(
                                        matchData.eventId,
                                        selection,
                                        matchData.back_odds,
                                        matchData.runners,
                                        matchData.marketId,
                                        matchData.matchSetting
                                          ? matchData.matchSetting
                                          : 10,
                                        matchData?.matchOdds,
                                        matchData?.status,
                                        matchData?.gameType
                                      )}
                                    {matchData?.lay_odds?.length &&
                                      renderLayOdds(
                                        matchData.eventId,
                                        selection,
                                        matchData.lay_odds,
                                        matchData.runners,
                                        matchData.marketId,
                                        matchData.matchSetting
                                          ? matchData.matchSetting
                                          : 10,
                                        matchData?.matchOdds,
                                        matchData?.status,
                                        matchData?.gameType
                                      )}
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </>
                  );
                })}
            </div>
          ) : (
            <p>There are currently no followed multi markets.</p>
          )}
        </div>

        {/* right_cols */}
        {/* RIGHT SECTION */}
        <button
          className="rightbar_btn d-block d-lg-none"
          onClick={() => showToggle()}
        >
          <i className="fa-solid fa-bars"></i>
        </button>

        <div className="col-right">
          <div className="theme_accordian">
            <Accordion defaultActiveKey="bets">
              <Accordion.Item eventKey="bets">
                <Accordion.Header>Bet Slip</Accordion.Header>
                <Accordion.Body>
                  <div className="bet_slip_data">
                    <div className="col-right-inner">
                      {betLoader ? (
                        <div class="loading-wrap">
                          <ul class="loading">
                            <li>
                              <img src={loading40} />
                            </li>
                            <li>Place Bets for {timeLeft} secs</li>
                          </ul>
                        </div>
                      ) : (
                        <>
                          <div className="message-bet d-none">
                            <h4 id="errorMsg" className="error">
                              Your back odds cannot bet less than or the same as
                              your lay odds for Martinez v S Kwon, Match Odds,
                              Soonwoo Kwon
                            </h4>
                            <p id="betMsg" className="warning"></p>
                          </div>

                          <p className="d-none">
                            Click on the odds to add selections to the betslip.{" "}
                          </p>

                          <div id="betSlipBoard" className="bet_slip">
                            <div
                              id="betList"
                              className="slip-list max-heightminus131"
                            >
                              {minAmountCon && (
                                <div class="message-bet">
                                  <h4 id="errorMsg" class="error">
                                    The stake you have entered are below the
                                    minimum.
                                  </h4>
                                </div>
                              )}
                              {/* slip_lay */}

                              <div className="bets_list_wrapper">
                                {/* BLUE PART START */}
                                {renderBackBetSlip(backBetSlipArray)}
                                {/* BLUE PART END */}

                                <div>
                                  {/* PINK PART START */}
                                  {renderLayBetSlip()}
                                  {/* PINK PART END */}
                                </div>
                              </div>
                            </div>
                          </div>
                          {allBetSlipData.length > 0 ? (
                            <div className="full_btn">
                              <div className="liability sum">
                                Liability
                                <span className="red">
                                  $
                                  {parseFloat(
                                    Math.abs(betFairLiability)
                                  ).toFixed(2)}
                                </span>
                              </div>

                              <ul className="btn-wrap">
                                <li>
                                  {/* <a className="btn" style={{ cursor: "pointer" }}>
                              Cancel All{" "}
                            </a> */}
                                  <button
                                    className="price-button-pay-slip btn"
                                    onClick={() => handelClearAllBet()}
                                  >
                                    Cancel All
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className="btn-send disable"
                                    style={
                                      disableBetFairPlaceBetBtn ||
                                        loaderPlaceBetfair
                                        ? {
                                          cursor: "not-allowed",
                                        }
                                        : {
                                          cursor: "pointer",
                                        }
                                    }
                                    onClick={() => handelPlaceBetfair()}
                                    disabled={
                                      disableBetFairPlaceBetBtn ||
                                      loaderPlaceBetfair
                                    }
                                    title={
                                      disableBetFairPlaceBetBtn &&
                                      "Please fill amount field"
                                    }
                                  >
                                    Place Bets{" "}
                                    {loaderPlaceBetfair && (
                                      <span style={{ marginLeft: "10px" }}>
                                        <Loader
                                          Style={{
                                            borderTop: "7px solid #FFB80C",
                                            borderBottom: "7px solid #FFB80C",
                                            width: "10px",
                                            height: "10px",
                                          }}
                                        />
                                      </span>
                                    )}
                                  </button>
                                </li>
                              </ul>

                              <ul className="slip-option">
                                <li>
                                  <input type="checkbox" />
                                  <label>Please confirm your bets.</label>{" "}
                                </li>
                              </ul>
                            </div>
                          ) : (
                            <div className="d-flex justify-content-center align-item-center mt-2">
                              <p style={{ fontSize: "13px" }}>
                                Click on the odds to add selections to the
                                betslip.
                              </p>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
      <Login show={showLogin} handleClose={() => setShowLogin(false)} />
    </div>
  );
};

export default MultiMarkets;
