import React from "react";
import { useContext } from "react";
import BetSlipContext from "../context/BetSlipContext";
import AuthContext from "../context/AuthContext";
import helpers from "../utils/helpers";
import { useNavigate } from "react-router-dom";
const OddsListing = (props) => {
  let { handelShowBetSlip, handelPlaceBetfair, activeOneClick } =
    useContext(BetSlipContext);
  let { loginUserDetailData } = useContext(AuthContext);
  const navigate = useNavigate();
  const runners = props?.matchData?.runners || [];
  const firstTeamSelectionId = runners[0]?.SelectionId;
  const secondTeamSelectionId = runners[1]?.SelectionId;
  if (runners.length > 2) {
    const drawSelectionId = runners[2].SelectionId;
  }

  let filterSocketBetfair = props?.matchData?.odds || [];
  var first_team_back_odds =
    (filterSocketBetfair?.rt &&
      filterSocketBetfair?.rt.filter((rt) => rt.ib)) ||
    [];
  first_team_back_odds = first_team_back_odds.filter(
    (todd) => todd.ri === firstTeamSelectionId
  );

  var first_team_lay_odds =
    (filterSocketBetfair?.rt &&
      filterSocketBetfair?.rt.filter((rt) => !rt.ib)) ||
    [];
  first_team_lay_odds = first_team_lay_odds.filter(
    (todd) => todd.ri === firstTeamSelectionId
  );

  var second_team_back_odds =
    (filterSocketBetfair?.rt &&
      filterSocketBetfair?.rt.filter((rt) => rt.ib)) ||
    [];
  second_team_back_odds = second_team_back_odds.filter(
    (todd) => todd.ri === secondTeamSelectionId
  );

  var second_team_lay_odds =
    (filterSocketBetfair?.rt &&
      filterSocketBetfair?.rt.filter((rt) => !rt.ib)) ||
    [];
  second_team_lay_odds = second_team_lay_odds.filter(
    (todd) => todd.ri === secondTeamSelectionId
  );

  const className =
    props?.matchData?.matchOdds == "on" ? "col-visit" : "col-draw";
  const offClass = props?.matchData?.matchOdds == "off" ? "cursor-none" : "";

  return (
    <>
      {filterSocketBetfair?.ms == 3 || filterSocketBetfair?.ms == 4 ? (
        <dd className={className}>
          <div className="suspend">
            <span>{helpers.marketStatus(filterSocketBetfair?.ms)}</span>
          </div>
        </dd>
      ) : (
        <>
          <dd className={className}>
            <button
              value={first_team_back_odds[0]?.rt}
              id={"back_odds_" + first_team_back_odds[0]?.rt}
              className={`btn-back btn-back-new " + ${offClass} ${
                document
                  .getElementById("back_odds_" + first_team_back_odds[0]?.rt)
                  ?.getAttribute("value") != first_team_back_odds[0]?.rt &&
                first_team_back_odds[0]?.rt &&
                "spark"
              }`}
              onClick={() =>
                navigate(
                  `/markets/${props?.matchData?.gameType}/${props?.matchData?.eventId}/${props?.matchData?.marketId}`
                )
              }
              style={
                first_team_back_odds[0]?.rt == 0 ||
                first_team_back_odds.length === 0 ||
                props.matchData?.matchOdds == "off" ||
                props?.matchData?.status !== "in_play" ||
                props?.matchData?.matchSetting.find(
                  (res) => res?.type === "betFaire"
                )?.oddsLimit <= first_team_back_odds[0]?.rt ||
                loginUserDetailData?.matchSetting?.find(
                  (res) =>
                    res?.type === "betFaire" &&
                    res?.sportType === props?.matchData?.gameType
                )?.oddsLimit <= first_team_back_odds[0]?.rt
                  ? { cursor: "not-allowed" }
                  : { cursor: "pointer" }
              }
            >
              {(first_team_back_odds && first_team_back_odds[0]?.rt) || ""}
            </button>
            <button
              value={first_team_lay_odds[0]?.rt}
              id={"back_odds_" + first_team_lay_odds[0]?.rt}
              className={`btn-lay btn-back-new " + ${offClass} ${
                document
                  .getElementById("back_odds_" + first_team_lay_odds[0]?.rt)
                  ?.getAttribute("value") != first_team_lay_odds[0]?.rt &&
                first_team_lay_odds[0]?.rt &&
                "spark"
              }`}
              onClick={() =>
                navigate(
                  `/markets/${props?.matchData?.gameType}/${props?.matchData?.eventId}/${props?.matchData?.marketId}`
                )
              }
              style={
                first_team_lay_odds[0]?.rt == 0 ||
                first_team_lay_odds.length === 0 ||
                props.matchData?.matchOdds == "off" ||
                props?.matchData?.status !== "in_play" ||
                props?.matchData?.matchSetting.find(
                  (res) => res?.type === "betFaire"
                )?.oddsLimit <= first_team_lay_odds[0]?.rt ||
                loginUserDetailData?.matchSetting?.find(
                  (res) =>
                    res?.type === "betFaire" &&
                    res?.sportType === props?.matchData?.gameType
                )?.oddsLimit <= first_team_lay_odds[0]?.rt
                  ? { cursor: "not-allowed" }
                  : { cursor: "pointer" }
              }
            >
              {(first_team_lay_odds && first_team_lay_odds[0]?.rt) || ""}
            </button>
          </dd>
        </>
      )}
      {/* {props?.matchData?.eventType != "2" && ( */}
        <dd className="col-draw">
          <button className="btn-back cursor-none btn-back-new">---</button>
          <button className="btn-lay cursor-none btn-back-new">---</button>
        </dd>

      {filterSocketBetfair?.ms == 3 || filterSocketBetfair?.ms == 4 ? (
        <dd className={className}>
          <div className="suspend">
            <span>{helpers.marketStatus(filterSocketBetfair?.ms)}</span>
          </div>
        </dd>
      ) : (
        <>
          <dd className={className}>
            <button
              value={second_team_back_odds[0]?.rt}
              id={"back_odds_" + second_team_back_odds[0]?.rt}
              className={`btn-back btn-back-new " + ${offClass} ${
                document
                  .getElementById("back_odds_" + second_team_back_odds[0]?.rt)
                  ?.getAttribute("value") != second_team_back_odds[0]?.rt &&
                second_team_back_odds[0]?.rt &&
                "spark"
              }`}
              onClick={() =>
                navigate(
                  `/markets/${props?.matchData?.gameType}/${props?.matchData?.eventId}/${props?.matchData?.marketId}`
                )
              }
              style={
                second_team_back_odds[0]?.rt == 0 ||
                second_team_back_odds.length === 0 ||
                props.matchData?.matchOdds == "off" ||
                props?.matchData?.status !== "in_play" ||
                props?.matchData?.matchSetting.find(
                  (res) => res?.type === "betFaire"
                )?.oddsLimit <= second_team_back_odds[0]?.rt ||
                loginUserDetailData?.matchSetting?.find(
                  (res) =>
                    res?.type === "betFaire" &&
                    res?.sportType === props?.matchData?.gameType
                )?.oddsLimit <= second_team_back_odds[0]?.rt
                  ? { cursor: "not-allowed" }
                  : { cursor: "pointer" }
              }
            >
              {(second_team_back_odds && second_team_back_odds[0]?.rt) || ""}
            </button>
            <button
              value={second_team_lay_odds[0]?.rt}
              id={"back_odds_" + second_team_lay_odds[0]?.rt}
              className={`btn-lay btn-back-new " + ${offClass} ${
                document
                  .getElementById("back_odds_" + second_team_lay_odds[0]?.rt)
                  ?.getAttribute("value") != second_team_lay_odds[0]?.rt &&
                second_team_lay_odds[0]?.rt &&
                "spark"
              }`}
              onClick={() =>
                navigate(
                  `/markets/${props?.matchData?.gameType}/${props?.matchData?.eventId}/${props?.matchData?.marketId}`
                )
              }
              style={
                second_team_lay_odds[0]?.rt == 0 ||
                second_team_lay_odds.length === 0 ||
                props.matchData?.matchOdds == "off" ||
                props?.matchData?.status !== "in_play" ||
                props?.matchData?.matchSetting.find(
                  (res) => res?.type === "betFaire"
                )?.oddsLimit <= second_team_lay_odds[0]?.rt ||
                loginUserDetailData?.matchSetting?.find(
                  (res) =>
                    res?.type === "betFaire" &&
                    res?.sportType === props?.matchData?.gameType
                )?.oddsLimit <= second_team_lay_odds[0]?.rt
                  ? { cursor: "not-allowed" }
                  : { cursor: "pointer" }
              }
            >
              {(second_team_lay_odds && second_team_lay_odds[0]?.rt) || ""}
            </button>
          </dd>
        </>
      )}
    </>
  );
};

export default OddsListing;
