import React from "react";
import { Popover } from "react-bootstrap";
const popoverPremium = () => {
  return (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Rules of Premium Cricket</Popover.Header>
      <Popover.Body>
        <div className="pop-body">
          {/* <ol>
          <li>
            Once all session/fancy bets are completed and settled there will be
            no reversal even if the Match is Tied or is Abandoned.
          </li>
          <li>
            Once all session/fancy bets are completed and settled there will be
            no reversal even if the Match is Tied or is Abandoned.
          </li>
        </ol> */}
          <ul className="list-unstyled mb-0">
            <li>
              <div>
                <figure
                  className="
              mb-0"
                >
                  <img src="../assets/images/icon-pdf.png" />
                </figure>
                <figcaption>
                  <h3 className="mb-0">
                    <a href="#">Pre Match Market Details</a>
                  </h3>
                </figcaption>
              </div>

              <div className="download">
                <a href="#">
                  {" "}
                  <img src="../assets/images/icon-download.png" alt="" />
                </a>
              </div>
            </li>

            <li>
              <div>
                <figure
                  className="
              mb-0"
                >
                  <img src="../assets/images/icon-pdf.png" />
                </figure>
                <figcaption>
                  <h3 className="mb-0">
                    <a href="#">Pre Match Market Details</a>
                  </h3>
                </figcaption>
              </div>

              <div className="download">
                <a href="#">
                  {" "}
                  <img src="../assets/images/icon-download.png" alt="" />
                </a>
              </div>
            </li>

            <li>
              <div>
                <figure
                  className="
              mb-0"
                >
                  <img src="../assets/images/icon-pdf.png" />
                </figure>
                <figcaption>
                  <h3 className="mb-0">
                    <a href="#">Pre Match Market Details</a>
                  </h3>
                </figcaption>
              </div>

              <div className="download">
                <a href="#">
                  {" "}
                  <img src="../assets/images/icon-download.png" alt="" />
                </a>
              </div>
            </li>
            <li>
              <div>
                <figure
                  className="
              mb-0"
                >
                  <img src="../assets/images/icon-pdf.png" />
                </figure>
                <figcaption>
                  <h3 className="mb-0">
                    <a href="#">Pre Match Market Details</a>
                  </h3>
                </figcaption>
              </div>

              <div className="download">
                <a href="#">
                  {" "}
                  <img src="../assets/images/icon-download.png" alt="" />
                </a>
              </div>
            </li>
          </ul>
          <div className="btn-wrap w-50 m-auto py-3">
            <button className="btn py-lg-2 py-1">Ok</button>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );
};
const popoverFancy = () => {
  return (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Rules of Premium Cricket</Popover.Header>
      <Popover.Body>
        <div className="pop-body">
          <ol>
            <li>
              Once all session/fancy bets are completed and settled there will
              be no reversal even if the Match is Tied or is Abandoned.
            </li>
            <li>
              Once all session/fancy bets are completed and settled there will
              be no reversal even if the Match is Tied or is Abandoned.
            </li>
          </ol>

          <div className="btn-wrap w-50 m-auto py-3">
            <button className="btn py-lg-2 py-1">Ok</button>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );
};
export { popoverPremium, popoverFancy };
