import React, { useState, useEffect } from "react";
import { Modal, Tabs, Tab, Form, Button } from "react-bootstrap";
import logoSkyexchange from "../assets/images/logosx.jpg";
import telegram from "../assets/images/brand-telegram.svg";
import whatsapp from "../assets/images/brand-whatsapp.svg";
import { useForm } from "react-hook-form";
import { toast } from "wc-toast";
import { apiGet, apiPost } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import countries from "../components/JsonData/Countries.json";

function App() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isLoader, setLoader] = useState(false);
  const [getType, setType] = useState("email");
  const [getSettingData, setSettingData] = useState({});

  const {
    register,
    handleSubmit,
    watch,
    reset,
    unregister,
    formState: { errors },
  } = useForm({});
  const handleType = (e) => {
    setType(e);
  };
  useEffect(() => {
    if (getType === "email") {
      register("email", {
        required: "Please enter email",
      });
      unregister("smsPhoneNumber");
      unregister("whatsAppPhoneNumber");
    } else if (getType === "sms") {
      register("smsPhoneNumber", {
        required: "Please enter a valid phone number",
      });
      unregister("email");
      unregister("whatsAppPhoneNumber");
    } else if (getType === "whatsapp") {
      register("whatsAppPhoneNumber", {
        required: "Please enter a valid phone number",
      });
      unregister("smsPhoneNumber");
      unregister("email");
    }
  }, [getType, unregister]);

  const onSubmit = async (request) => {
    setLoader(true);
    if (getType === "email") {
      var fieldValue = request.email;
    } else if (getType === "sms") {
      var countryCode = request.smsCountryCode;
      var fieldValue = request.smsPhoneNumber;
    } else if (getType === "whatsapp") {
      var countryCode = request.whatsappCountryCode;
      var fieldValue = request.whatsAppPhoneNumber;
    }
    const { status, data: response_users } = await apiPost(
      apiPath.userSubscribe,
      { type: getType, fieldValue: fieldValue, countryCode: countryCode }
    );
    if (status === 200) {
      if (response_users.success) {
        setLoader(false);
        handleClose();
        toast.success(response_users.message);
        reset();
      } else {
        setLoader(false);
        toast.error(response_users.message);
      }
    }
  };

  const settingData = async () => {
    const { status, data: response_users } = await apiGet(apiPath.adminSetting);
    if (status === 200) {
      if (response_users.success) {
        setSettingData(response_users.results);
      }
    }
  };
  useEffect(() => {
    settingData();
  }, []);

  const handleShareButton = () => {
    // Check if navigator.share is supported by the browser
    if (navigator.share) {
      // console.log("Congrats! Your browser supports Web Share API");
      navigator
        .share({
          url: `/signup`,
        })
        .then(() => {
          // console.log("Sharing successfull");
        })
        .catch(() => {
          // console.log("Sharing failed");
        });
    } else {
      // console.log("Sorry! Your browser does not support Web Share API");
    }
  };
  return (
    <div className="signup-black">
      <div className="get_query-wrapper">
        <div className="get_query-head">
          <button className="upload-icon" onClick={handleShareButton}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              enableBackground="new 0 0 24 24"
              className="sc-gKsewC iPWGYb"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.6464 3.85347L11 4.20702L11.7071 3.49992L11.3536 3.14636L8.35355 0.146362H7.64645L4.64645 3.14636L4.29289 3.49992L5 4.20702L5.35355 3.85347L7.5 1.70702V9.49992V9.99992H8.5V9.49992V1.70702L10.6464 3.85347ZM1 5.49994L1.5 4.99994H4V5.99994H2V14.9999H14V5.99994H12V4.99994H14.5L15 5.49994V15.4999L14.5 15.9999H1.5L1 15.4999V5.49994Z"
                fill="currentColor"
              ></path>
            </svg>
          </button>
          <button className="subscribe" onClick={handleShow}>
            <svg
              viewBox="0 0 19 20"
              enableBackground="new 0 0 24 24"
              className="sc-gKsewC gMPWlu"
            >
              <path d="M1.72149 16.0526H16.6118C17.6622 16.0526 18.3333 15.4289 18.3333 14.5127C18.3333 13.46 17.5066 12.6121 16.641 11.8519C15.931 11.2281 15.8046 9.87329 15.6101 8.42105C15.3767 5.28265 14.3068 2.97271 12.0212 2.14425C11.5933 0.916179 10.5234 0 9.1618 0C7.8099 0 6.73033 0.916179 6.31211 2.14425C4.02653 2.97271 2.95668 5.28265 2.71353 8.42105C2.52874 9.87329 2.39257 11.2281 1.68258 11.8519C0.816976 12.6121 0 13.46 0 14.5127C0 15.4289 0.671088 16.0526 1.72149 16.0526ZM9.1618 20C10.893 20 12.1477 18.7914 12.2644 17.3782H6.05924C6.17595 18.7914 7.44032 20 9.1618 20Z"></path>
            </svg>
            Subscribe
          </button>
        </div>

        <div className="page-middle-header">
          <span>
            {" "}
            <a href="/">
              <img src={logoSkyexchange} alt="" />
            </a>{" "}
          </span>
          <h1>Get your Cricket Id</h1>
          <strong>Play with the Best Betting Website in India. </strong>
        </div>

        <div className="query_link_wrapper">
          <ul>
            <li>
              <a
                href={"https://wa.me/" + getSettingData?.whatsapp1}
                target="_blank"
              >
                <span>
                  <img src={whatsapp} />
                </span>
                <span>Whatsapp now</span>
                <span className="upload-right">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    enableBackground="new 0 0 24 24"
                    className="sc-gKsewC iPWGYb"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.6464 3.85347L11 4.20702L11.7071 3.49992L11.3536 3.14636L8.35355 0.146362H7.64645L4.64645 3.14636L4.29289 3.49992L5 4.20702L5.35355 3.85347L7.5 1.70702V9.49992V9.99992H8.5V9.49992V1.70702L10.6464 3.85347ZM1 5.49994L1.5 4.99994H4V5.99994H2V14.9999H14V5.99994H12V4.99994H14.5L15 5.49994V15.4999L14.5 15.9999H1.5L1 15.4999V5.49994Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>
              </a>
            </li>

            <li>
              <a
                href={"https://wa.me/" + getSettingData?.whatsapp2}
                target="_blank"
              >
                <span>
                  <img src={whatsapp} />
                </span>
                <span>Whatsapp now</span>
                <span className="upload-right">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    enableBackground="new 0 0 24 24"
                    className="sc-gKsewC iPWGYb"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.6464 3.85347L11 4.20702L11.7071 3.49992L11.3536 3.14636L8.35355 0.146362H7.64645L4.64645 3.14636L4.29289 3.49992L5 4.20702L5.35355 3.85347L7.5 1.70702V9.49992V9.99992H8.5V9.49992V1.70702L10.6464 3.85347ZM1 5.49994L1.5 4.99994H4V5.99994H2V14.9999H14V5.99994H12V4.99994H14.5L15 5.49994V15.4999L14.5 15.9999H1.5L1 15.4999V5.49994Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>
              </a>
            </li>

            <li>
              <a
                href={"https://wa.me/" + getSettingData?.whatsapp3}
                target="_blank"
              >
                <span>
                  <img src={whatsapp} />
                </span>
                <span>Whatsapp now</span>
                <span className="upload-right">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    enableBackground="new 0 0 24 24"
                    className="sc-gKsewC iPWGYb"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.6464 3.85347L11 4.20702L11.7071 3.49992L11.3536 3.14636L8.35355 0.146362H7.64645L4.64645 3.14636L4.29289 3.49992L5 4.20702L5.35355 3.85347L7.5 1.70702V9.49992V9.99992H8.5V9.49992V1.70702L10.6464 3.85347ZM1 5.49994L1.5 4.99994H4V5.99994H2V14.9999H14V5.99994H12V4.99994H14.5L15 5.49994V15.4999L14.5 15.9999H1.5L1 15.4999V5.49994Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>
              </a>
            </li>

            <li>
              <a
                href={"https://t.me/" + getSettingData?.telegram1}
                target="_blank"
              >
                <span>
                  <img src={telegram} />
                </span>
                <span>Telegram Now</span>
                <span className="upload-right">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    enableBackground="new 0 0 24 24"
                    className="sc-gKsewC iPWGYb"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.6464 3.85347L11 4.20702L11.7071 3.49992L11.3536 3.14636L8.35355 0.146362H7.64645L4.64645 3.14636L4.29289 3.49992L5 4.20702L5.35355 3.85347L7.5 1.70702V9.49992V9.99992H8.5V9.49992V1.70702L10.6464 3.85347ZM1 5.49994L1.5 4.99994H4V5.99994H2V14.9999H14V5.99994H12V4.99994H14.5L15 5.49994V15.4999L14.5 15.9999H1.5L1 15.4999V5.49994Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>
              </a>
            </li>

            <li>
              <a
                href={"https://t.me/" + getSettingData?.telegram2}
                target="_blank"
              >
                <span>
                  <img src={telegram} />
                </span>
                <span>Telegram Now</span>
                <span className="upload-right">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    enableBackground="new 0 0 24 24"
                    className="sc-gKsewC iPWGYb"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.6464 3.85347L11 4.20702L11.7071 3.49992L11.3536 3.14636L8.35355 0.146362H7.64645L4.64645 3.14636L4.29289 3.49992L5 4.20702L5.35355 3.85347L7.5 1.70702V9.49992V9.99992H8.5V9.49992V1.70702L10.6464 3.85347ZM1 5.49994L1.5 4.99994H4V5.99994H2V14.9999H14V5.99994H12V4.99994H14.5L15 5.49994V15.4999L14.5 15.9999H1.5L1 15.4999V5.49994Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>
              </a>
            </li>
          </ul>
        </div>

        <Modal show={show} onHide={handleClose} className="subscribe_modal">
          <div className="subscribe_modal_content">
            <div className="page-middle-header">
              <span>
                {" "}
                <img src={logoSkyexchange} alt="" />{" "}
              </span>
              <h1>Subscribe to OnlineBettingWebsite</h1>
              <strong>Stay up to date with everything important. </strong>
            </div>
            <a href="#" className="login_close" onClick={handleClose}>
              <i className="fas fa-times"></i>
            </a>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="tabs_content">
                <Tabs
                  defaultActiveKey="email"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                  onSelect={(e) => handleType(e)}
                >
                  <Tab eventKey="email" title="Email">
                    <div className="message_wrapper">
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder="email"
                          className={errors.email ? "is-invalid" : ""}
                          {...register("email", {
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Invalid email address.",
                            },
                          })}
                        />

                        {errors.email && errors.email.message && (
                          <label className="invalid-feedback text-left">
                            {errors.email.message}
                          </label>
                        )}
                      </Form.Group>
                      {/* <input type="text" placeholder="Email" /> */}
                    </div>
                  </Tab>
                  <Tab eventKey="sms" title="SMS">
                    <div className="message_wrapper">
                      <div className="select_phone_number_adone">
                        <select {...register("smsCountryCode")}>
                          {countries.map((key, item) => (
                            <option
                              value={key.dial_code}
                              role={item}
                              key={key.code}
                              selected={key.dial_code == "+91"}
                            >
                              {key.dial_code}
                            </option>
                          ))}
                        </select>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            placeholder="Phone Number"
                            className={
                              errors.smsPhoneNumber ? "is-invalid" : ""
                            }
                            {...register("smsPhoneNumber", {
                              minLength: {
                                value: 10,
                                message:
                                  "Mobile number should contain at least 10 digits.",
                              },
                              maxLength: {
                                value: 10,
                                message:
                                  "Mobile number should not exceed 10 digits.",
                              },
                              pattern: {
                                value: /^[0-9\b]+$/i,
                                message: "Mobile number format is invalid.",
                              },
                            })}
                          />
                          {errors.smsPhoneNumber &&
                            errors.smsPhoneNumber.message && (
                              <label className="invalid-feedback text-left">
                                {errors.smsPhoneNumber.message}
                              </label>
                            )}
                        </Form.Group>
                        {/* <input type="text" placeholder="Phone Number" /> */}
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="whatsapp" title="whatsapp">
                    <div className="message_wrapper">
                      <div className="select_phone_number_adone">
                        <select {...register("whatsappCountryCode")}>
                          {countries.map((key, item) => (
                            <option
                              value={key.dial_code}
                              role={item}
                              key={key.code}
                              selected={key.dial_code == "+91"}
                            >
                              {key.dial_code}
                            </option>
                          ))}
                        </select>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            placeholder="Phone Number"
                            className={
                              errors.whatsAppPhoneNumber ? "is-invalid" : ""
                            }
                            {...register("whatsAppPhoneNumber", {
                              minLength: {
                                value: 10,
                                message:
                                  "Mobile number should contain at least 10 digits.",
                              },
                              maxLength: {
                                value: 10,
                                message:
                                  "Mobile number should not exceed 10 digits.",
                              },
                              pattern: {
                                value: /^[0-9\b]+$/i,
                                message: "Mobile number format is invalid.",
                              },
                            })}
                          />
                          {errors.whatsAppPhoneNumber &&
                            errors.whatsAppPhoneNumber.message && (
                              <label className="invalid-feedback text-left">
                                {errors.whatsAppPhoneNumber.message}
                              </label>
                            )}
                        </Form.Group>
                        {/* <input type="text" placeholder="Phone Number" /> */}
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>

              <div className="condition">
                <ul>
                  <li>
                    <label>
                      <Form.Group>
                        <input
                          type="checkbox"
                          {...register("privacy", {
                            required:
                              "Please accept Terms and Conditions and Privacy Notice",
                          })}
                          className={errors.privacy ? "is-invalid" : ""}
                        />{" "}
                        I agree to <a href="#">Terms and Conditions</a> and{" "}
                        <a href="#">Privacy</a> Notice. <span>(Required)</span>
                        {errors.privacy && errors.privacy.message && (
                          <label className="invalid-feedback text-left">
                            {errors.privacy.message}
                          </label>
                        )}
                      </Form.Group>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type="checkbox" />I agree to my contact details
                      being shared with OnlineBettingWebsite, who may contact
                      me.
                    </label>
                  </li>
                </ul>
              </div>
              <Button type="submit" className="modal_subscribe_btn">
                {isLoader ? "Loading..." : "Subscribe"}
              </Button>
              {/* <button className="modal_subscribe_btn">Subscribe</button> */}
            </Form>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default App;
