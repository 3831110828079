import React, { useState, useContext, useRef, useEffect } from "react";
import { Container, Col, Row, Tabs, Tab } from "react-bootstrap";
import AuthContext from "../context/AuthContext";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";
import { apiPost } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import { toast } from "wc-toast";
import { easyToGoCasino } from "../utils/constants";
function LiveCasino() {
  let { user, casinoShow, handleShow, casinoType, setCasinoType } =
    useContext(AuthContext);
  const [isLoader, setLoader] = useState(false);
  const [subData, setSubData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const launchGame = async (prod, type) => {
    const { status, data: response_users } = await apiPost(
      apiPath.doLoginAndLaunchEasyToCasino,
      { prod: prod, type: type }
    );
    if (status === 200) {
      if (response_users.status) {
        javascript: window.open(
          response_users.data.url,
          "_blank",
          "height=900,width=1200"
        );
      } else {
        toast.error(response_users.message);
      }
    }
  };

  return (
    <div>
      <div className="main">
        <Container>
          <div className="without-left-nav">
            <div className="inplay">
              <div className="theme_tabs live-casino-theme-tabs">
                <Row className="gx-2">
                  <Col md={12} className="my-2">
                    {" "}
                    <a href="#">
                      <img src="assets/images/home/home_slider1.jpg" />
                    </a>
                  </Col>
                  {easyToGoCasino.map((item, index) => {
                    return (
                      <Col lg={3} xs={6} className="mb-2">
                        {" "}
                        <Link
                          href="#"
                          className="position-relative d-block games_main live_casino_game"
                          onClick={() =>
                            !isEmpty(user)
                              ? launchGame(item?.code, item?.productTypeCode)
                              : handleShow()
                          }
                        >
                          <img src={item?.image} />
                          <div className="title">
                            <h2>{item?.product}</h2>
                            <span>Play Now</span>
                          </div>
                        </Link>
                      </Col>
                    );
                  })}

                  {/* <Col lg={3} xs={6} className="mb-2">
                    {" "}
                    <Link
                      href="#"
                      className="position-relative d-block games_main"
                      onClick={() =>
                        !isEmpty(user)
                          ? launchGame("HORSEBOOK", "LIVE")
                          : handleShow()
                      }
                    >
                      <img src="assets/images/home/banner_horsebook-half.jpg" />
                      <div className="title">
                        <h2>Supernowa</h2>
                        <span>Play Now</span>
                      </div>
                    </Link>
                  </Col>
                  <Col lg={3} xs={6} className="mb-2">
                    {" "}
                    <a
                      href="#"
                      className="position-relative d-block games_main"
                    >
                      <img src="assets/images/home/banner_minesweeper-half.jpg" />
                      <div className="title">
                        <h2>Minesweeper</h2>
                        <span>Play Now</span>
                      </div>
                    </a>
                  </Col>
                  <Col lg={3} xs={6} className="mb-2">
                    {" "}
                    <a
                      href="#"
                      className="position-relative d-block games_main"
                    >
                      <img src="assets/images/home/banner_skytrader-half.jpg" />
                      <div className="title">
                        <h2>Skytrader</h2>
                        <span>Play Now</span>
                      </div>
                    </a>
                  </Col>
                  <Col lg={3} xs={6} className="mb-2">
                    {" "}
                    <a
                      href="#"
                      className="position-relative d-block games_main"
                    >
                      <img src="assets/images/home/banner_32card-half.jpg" />
                      <div className="title">
                        <h2>32card</h2>
                        <span>Play Now</span>
                      </div>
                    </a>
                  </Col>
                  <Col lg={3} xs={6} className="mb-2">
                    {" "}
                    <a
                      href="#"
                      className="position-relative d-block games_main"
                    >
                      <img src="assets/images/home/banner_rummy-half.jpg" />
                      <div className="title">
                        <h2>Rummy</h2>
                        <span>Play Now</span>
                      </div>
                    </a>
                  </Col>
                  <Col lg={3} xs={6} className="mb-2">
                    {" "}
                    <a
                      href="#"
                      className="position-relative d-block games_main"
                    >
                      <img src="assets/images/home/banner_betgames-half.jpg" />
                      <div className="title">
                        <h2>Betgames</h2>
                        <span>Play Now</span>
                      </div>
                    </a>
                  </Col>
                  <Col lg={3} xs={6} className="mb-2">
                    {" "}
                    <a
                      href="#"
                      className="position-relative d-block games_main"
                    >
                      <img src="assets/images/home/banner_andarBahar-half.jpg" />
                      <div className="title">
                        <h2>AndarBahar</h2>
                        <span>Play Now</span>
                      </div>
                    </a>
                  </Col>
                  <Col lg={3} xs={6} className="mb-2">
                    {" "}
                    <a
                      href="#"
                      className="position-relative d-block games_main"
                    >
                      <img src="assets/images/home/banner_sicbo-half.jpg" />
                      <div className="title">
                        <h2>Sicbo</h2>
                        <span>Play Now</span>
                      </div>
                    </a>
                  </Col>
                  <Col lg={3} xs={6} className="mb-2">
                    {" "}
                    <a
                      href="#"
                      className="position-relative d-block games_main"
                    >
                      <img src="assets/images/home/banner_sevenUpDown-half.jpg" />
                      <div className="title">
                        <h2>7 Up 7 Down</h2>
                        <span>Play Now</span>
                      </div>
                    </a>
                  </Col>
                  <Col lg={3} xs={6} className="mb-2">
                    {" "}
                    <a
                      href="#"
                      className="position-relative d-block games_main"
                    >
                      <img src="assets/images/home/banner_CoinToss-half.jpg" />
                      <div className="title">
                        <h2>Coin Toss</h2>
                        <span>Play Now</span>
                      </div>
                    </a>
                  </Col>
                  <Col lg={3} xs={6} className="mb-2">
                    {" "}
                    <a
                      href="#"
                      className="position-relative d-block games_main"
                    >
                      <img src="assets/images/home/banner_teenPatti-half.jpg" />
                      <div className="title">
                        <h2>Teen Patti</h2>
                        <span>Play Now</span>
                      </div>
                    </a>
                  </Col>
                  <Col lg={3} xs={6} className="mb-2">
                    {" "}
                    <a
                      href="#"
                      className="position-relative d-block games_main"
                    >
                      <img src="assets/images/home/banner_cardMatka-half.jpg" />
                      <div className="title">
                        <h2>Card Matka</h2>
                        <span>Play Now</span>
                      </div>
                    </a>
                  </Col>
                  <Col lg={3} xs={6} className="mb-2">
                    {" "}
                    <a
                      href="#"
                      className="position-relative d-block games_main"
                    >
                      <img src="assets/images/home/banner_numberMatka-half.jpg" />
                      <div className="title">
                        <h2>Number Matka</h2>
                        <span>Play Now</span>
                      </div>
                    </a>
                  </Col>
                  <Col lg={3} xs={6} className="mb-2">
                    {" "}
                    <a
                      href="#"
                      className="position-relative d-block games_main"
                    >
                      <img src="assets/images/home/banner_binary-half.jpg" />
                      <div className="title">
                        <h2>Binary</h2>
                        <span>Play Now</span>
                      </div>
                    </a>
                  </Col>
                  <Col lg={3} xs={6} className="mb-2">
                    {" "}
                    <a
                      href="#"
                      className="position-relative d-block games_main"
                    >
                      <img src="assets/images/home/banner_bpoker-half.jpg" />
                      <div className="title">
                        <h2>Bpoker</h2>
                        <span>Play Now</span>
                      </div>
                    </a>
                  </Col> */}
                </Row>
              </div>
            </div>
            {/* right_cols */}
          </div>
        </Container>
      </div>
    </div>
  );
}

export default LiveCasino;
