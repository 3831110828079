const pathObj = {
  loginUser: "/v1/user/login",
  refreshToken: "/v1/user/refresh-token",
  seriesList: "/v1/user/match/series",
  matchList: "/v1/user/match/list",
  refreshAmount: "/v1/user/refresh-amount",
  userProfile: "/v1/user/profile",
  userProfileUpdate: "/v1/user/profile-update",
  userEditStake: "/v1/user/edit-stake",
  userEditOneClickStake: "/v1/user/edit-one-click-stake",
  activityLogs: "/v1/user/activity-logs",
  changePassword: "/v1/user/change-password",
  transactionBetLogs: "/v1/user/transaction/bet-logs",
  transactionLogs: "/v1/user/transaction/logs",
  matchDetail: "/v1/user/match/detail",
  matchScore: "/v1/user/match/score",
  resultList: "/v1/user/match/result-list",
  sportsList: "/v1/user/sport/list",
  inPlayCount: "/v1/user/sport/inplay-count",
  getMatchOdds: "/v1/cron/match-odds",
  betfairPlaceBet: "/v1/user/match/betfaire-place-bet",
  soccerPlaceBet: "/v1/user/match/soccer-place-bet",
  tennisPlaceBet: "/v1/user/match/tennis-place-bet",
  userInPlaySport: "/v1/user/sport",
  diamondfancyBetPlace: "/v1/user/match/diamond-fancy-place-bet",
  bookmakerBet: "/v1/user/match/bookmaker-place-bet",
  betPosition: "/v1/user/match/get-bet-positions",
  fancyBetPosition: "/v1/user/match/fancy-bet-positions",
  fancyBetRunPosition: "/v1/user/match/fancy-bet-run-positions",
  betEventsBetPosition: "/v1/user/match/get-events-bet-positions",
  multiEventPlaceBet: "/v1/user/match/multiple-event-place-bet",
  getCurrentBetsExchanges: "/v1/user/match/my-bets",
  getMyTieBets: "/v1/user/match/my-tie-bets",
  getMyTossBets: "/v1/user/match/my-toss-bets",
  cancelUnmatchedBets: "/v1/user/match/clear-unmatched-bet",
  getBookmakerBets: "/v1/user/match/my-bookmaker-bets",
  getFancyBets: "/v1/user/match/my-fancy-bets",
  getSportsBook: "/v1/user/match/my-sport-book-bets",
  fancyBetPlace: "/v1/user/match/fancy-place-bet",
  multiMarket: "/v1/user/sport/multi-market",
  preminumFancyBets: "/v1/user/match/premium-fancy-place-bet",
  addMatchInMultiMarket: "/v1/user/sport/multi-market",
  getPremiumFancy: "/v1/cron/premium-fancy",
  getBetsData: "/v1/user/match/events-bets",
  getHistory: "/v1/user/match/bet-history",
  getProfitLoss: "/v1/user/match/profit-loss",
  messageList: "/v1/user/message/list",
  userSubscribe: "/v1/user/subscribe",
  adminSetting: "/v1/user/site-setting/site-setting-list",
  searchEvent: "/v1/user/match/search-list",
  casinoWalletLogin: "/v1/wallet/login",
  doLoginAndLaunchGame: "/v1/wallet/doLoginAndLaunchGame",
  casinoAmountAdd: "/v1/user/casino-amount-add",
  withdrawCasinoAmount: "/v1/wallet/withdraw",
  awcBalance: "/v1/wallet/get-awc-balance",
  easytogoCasinoAmountAdd: "/v1/user/easytogo-casino-amount-add",
  doLoginAndLaunchEasyToCasino: "/v1/wallet/doLoginAndLaunchEasyToCasino",
  gameList: "/v1/wallet/gameList",
  casinoWalletHistory: "/v1/wallet/client-transaction-history",
  beforeBetPlace: "/v1/user/match/check-bet-price",
  getInternationalCasinoBets:"/v1/user/match/casino-bet-history",
  currentParlay:"/v1/user/match/my-parlay-bets",
  betPremiumFancyPosition: "/v1/user/match/sport-book-bet-positions",
  getFancyOdds: "/v1/cron/fancy-odds",
  getBanner:"/v1/user/get-home-banners",
  doLoginAndLaunchIQCasino: "/v1/wallet/doLoginAndLaunchIQCasino",

};
export default pathObj;
