import React, { useState, useEffect, useContext } from "react";
import {
  Tabs,
  Tab,
  Accordion,
  OverlayTrigger,
  Form,
  Row,
  Col,
  Container,
  Popover,
  Button,
  Table,
} from "react-bootstrap";
import Rightbar from "../components/Rightbar";
import { apiGet } from "../utils/apiFetch";
import helpers from "../utils/helpers";
import apiPath from "../utils/apiPath";
import { useForm } from "react-hook-form";
import { isEmpty, startCase } from "lodash";
import AuthContext from "../context/AuthContext";
import BetSlipContext from "../context/BetSlipContext";
import OddsListing from "../components/OddsListing";
import { Link } from "react-router-dom";
import moment from "moment";
import io from "socket.io-client";

const Inplay = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({});
  const [isLoader, setLoader] = useState(false);
  const [checkedList, setCheckedList] = useState(["all", "1", "2", "4"]);
  const [getInPlaySports, setInPlaySports] = useState([]);
  const [getAllSports, setAllSports] = useState([]);
  const [centralizedIds, setCentralizedIds] = useState("");
  let { handelAddMultiMarket, runApi } = useContext(BetSlipContext);
  let {
    user,
    inPlayScore,
    loginUserDetailData,
    setInplaySocket,
    inplaySocket,
  } = useContext(AuthContext);
  const setCheckBoxData = (msgId) => {
    if (msgId === "all") {
      if (checkedList.length === 4) {
        setCheckedList([]);
      } else {
        setCheckedList(["all", "1", "2", "4"]);
      }
    } else {
      const newCheckedList = [...checkedList];
      const index = newCheckedList.indexOf(msgId);
      if (index === -1) {
        newCheckedList.push(msgId);
      } else {
        newCheckedList.splice(index, 1);
      }
      setCheckedList(newCheckedList);
    }
  };

  const onSubmit = async (request) => {
    setLoader(true);
    if (isEmpty(checkedList)) {
      setLoader(false);
      document.body.click();
    } else {
      let temp = checkedList ? `?keyword=${checkedList}` : "";
      const { status, data: response_users } = await apiGet(
        apiPath.userInPlaySport + temp
      );
      if (status === 200) {
        if (response_users.success) {
          setLoader(false);
          reset();
          document.body.click();
        } else {
          setLoader(false);
          document.body.click();
        }
      }
    }
  };

  const fetchAllSport = async () => {
    let temp = user?.user?._id ? `?userId=${user?.user?._id}` : "";
    const { status, data: response_users } = await apiGet(
      apiPath.userInPlaySport + temp
    );
    if (status === 200) {
      if (response_users.success) {
        setAllSports(response_users.results);
        setInPlaySports(response_users.results.inPlay);
        const marketIds = response_users.results.inPlay.map(
          (r) => r.centralizedId
        );
        if (marketIds && marketIds.length) {
          setCentralizedIds(marketIds);
        }
        if (response_users?.results?.inPlay?.length > 0) {
          getOdds(
            response_users?.results?.inPlay?.map((res) => {
              return res?.marketId;
            })
          );
        }
      }
    }
  };

  useEffect(() => {
    if (inPlayScore?.length > 0) {
      setInPlaySports((current) => {
        return current.map((res) => {
          let temp = inPlayScore.find((item) => item?.eventId == res?.eventId);
          return {
            ...res,
            score: temp?.eventId == res?.eventId ? temp?.score : "",
            timeElapsed: temp?.eventId == res?.eventId ? temp?.timeElapsed : "",
          };
        });
      });
    }
  }, [inPlayScore]);

  useEffect(() => {
    fetchAllSport();
  }, []);

  useEffect(() => {
    fetchAllSport();
  }, [runApi]);

  const getOdds = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOdds + `?marketId=${id?.join(",")}&multi=true`
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results?.length > 0) {
          setInPlaySports((current) =>
            current.map((obj) => {
              let newObj = response_users?.results?.find((res) => {
                return res?.mi == obj?.marketId;
              });
              if (obj?.marketId == newObj?.mi) {
                return { ...obj, odds: newObj };
              }
              return obj;
            })
          );
        }
      }
    }
  };

  const [checkRefresh, setCheckRefresh] = useState(true);
  const [socketObj, setSocketObj] = useState(null);
  const [score, setScore] = useState([]);
  const getScore = (message) => {
    setScore(message);
  };
  const getSocket = () => {
    if (checkRefresh) {
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
      if (!isEmpty(inplaySocket)) {
        inplaySocket.disconnect();
      }
      setCheckRefresh(false);
      let newSocket = "";
      let randomId = user?.user?._id ? user?.user?._id : 112233;
      if (!isEmpty(user)) {
        newSocket = io(
          `${process.env.REACT_APP_API_BASE_URL2}?token=${randomId}&userType=front`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      } else {
        newSocket = io(`${process.env.REACT_APP_API_BASE_URL2}?user_id=${randomId}`, {
          transports: ["websocket"],
          forceNew: true,
        });
      }
      setSocketObj(newSocket);
      setInplaySocket(newSocket);
      newSocket.on("scoreAll", getScore);
      newSocket.on("disconnect", function () {
        setCheckRefresh(true);
      });
      return () => newSocket.close();
    }
  };

  useEffect(() => {
    if (checkRefresh) {
      getSocket();
    }
  }, [checkRefresh]);

  document.addEventListener("visibilitychange", function () {
    if (!document.hidden && !checkRefresh) {
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
      setCheckRefresh(true);
    } else {
      setCheckRefresh(false);
    }
  });

  const popover = (
    <Popover id="popover-basic" className="filter_popover">
      <Popover.Body>
        <Container>
          <Form className="filter_menu" onSubmit={handleSubmit(onSubmit)}>
            <Row className="gx-0">
              <Col md={6}>
                <div>
                  <Form.Group className="mb-0" controlId="formBasicCheckbox">
                    <Form.Check
                      label="All"
                      checked={
                        !isEmpty(checkedList) && checkedList.includes("all")
                      }
                      value="all"
                      onClick={() => setCheckBoxData("all")}
                    />
                  </Form.Group>
                </div>

                <div>
                  <Form.Group className="mb-0" controlId="formBasicCheckbox2">
                    <Form.Check
                      label="Tennis"
                      checked={
                        !isEmpty(checkedList) && checkedList.includes("2")
                      }
                      value="2"
                      onClick={() => setCheckBoxData("2")}
                    />
                  </Form.Group>
                </div>
              </Col>
              <Col md={6}>
                <div>
                  <Form.Group className="mb-0" controlId="formBasicCheckbox4">
                    <Form.Check
                      label="Soccer"
                      checked={
                        !isEmpty(checkedList) && checkedList.includes("1")
                      }
                      value="1"
                      onClick={() => setCheckBoxData("1")}
                    />
                  </Form.Group>
                </div>
                <div>
                  <Form.Group className="mb-0" controlId="formBasicCheckbox5">
                    <Form.Check
                      label="Cricket"
                      checked={
                        !isEmpty(checkedList) && checkedList.includes("4")
                      }
                      value="4"
                      onClick={() => setCheckBoxData("4")}
                    />
                  </Form.Group>
                </div>
              </Col>

              <ul className="btn-wrap">
                <li className="col-send">
                  <Button type="submit" className="btn-send m-0">
                    {isLoader ? "Loading..." : "Save"}
                  </Button>
                </li>
                <li className="close_btn me-4">
                  <Button className="btn" onClick={() => document.body.click()}>
                    Cancel{" "}
                  </Button>
                </li>
              </ul>
            </Row>
          </Form>
        </Container>
      </Popover.Body>
    </Popover>
  );
  return (
    <>
      <div className="full-wrap  without-left-nav inplay_page_wrap">
        <div className="col-center inplay">
          <div className="theme_tabs">
            <Tabs defaultActiveKey="inplay">
              <Tab eventKey="inplay" title="Inplay">
                <div className="theme_accordian">
                  <Accordion defaultActiveKey={["Cricket", "Tennis", "Soccer"]}>
                    <Accordion.Item eventKey="Cricket">
                      <Accordion.Header>Cricket</Accordion.Header>
                      <Accordion.Body>
                        <div className="game_table_main_wrapper">
                          <div className="game_table_inner">
                            <ul className="slip-head d-flex">
                              <li className="col-game"></li>
                              <li className="col-matched text-end">Matched</li>
                              <li className="col-visit text-center">1</li>
                              <li className="col-draw text-center">x</li>
                              <li className="col-home text-center">2</li>
                              <li className="col-info"></li>
                            </ul>

                            <div className="game-list">
                              {getInPlaySports &&
                                getInPlaySports.map((matchData, matchKey) => {
                                  let obj = moment.duration(
                                    moment(matchData?.eventDateTime).diff(
                                      moment(new Date())
                                    )
                                  )._data;
                                  return (
                                    matchData.eventType == "4" && (
                                      <dl
                                        className="game-list-col d-flex"
                                        key={matchKey + 1}
                                      >
                                        <dt className="col-matched">
                                          <Link
                                            to={
                                              "/markets/cricket/" +
                                              matchData.eventId +
                                              "/" +
                                              matchData.marketId
                                            }
                                          >
                                            {matchData?.status == "in_play" ? (
                                              <img
                                                className="icon-in_play"
                                                src="../assets/images/transparent.gif"
                                                alt="No_Image"
                                              />
                                            ) : (
                                              <img
                                                className="icon-no_play"
                                                src="../assets/images/transparent.gif"
                                              />
                                            )}
                                            {/* <span className="game-low_liq d-none">Low Liquidity</span> */}
                                            <span className="game-titlesmall">
                                              {matchData?.score
                                                ? `${
                                                    matchData?.eventName.split(
                                                      "v"
                                                    )[0]
                                                  } ${matchData?.score} ${
                                                    matchData?.eventName.split(
                                                      "v"
                                                    )[1]
                                                  }`
                                                : matchData?.eventName}

                                              {/* {matchData.eventName} */}
                                            </span>

                                            {obj.days == 0 &&
                                            obj.hours == 0 &&
                                            obj?.years == 0 &&
                                            obj?.months == 0 &&
                                            obj.minutes > 0 &&
                                            obj?.minutes <
                                              loginUserDetailData?.beforeInPlay ? (
                                              <span className="game-list-time">
                                                Starting in {obj?.minutes}'
                                              </span>
                                            ) : matchData.status ==
                                              "in_play" ? (
                                              <span className="in_play">
                                                In-Play {matchData?.timeElapsed}
                                              </span>
                                            ) : (
                                              ""
                                            )}

                                            {matchData?.channel !== "false" && (
                                              <span className="game-live"></span>
                                            )}

                                            {matchData?.fancyList &&
                                              matchData?.fancyList.length >
                                                0 && (
                                                <span className="game-fancy in-play"></span>
                                              )}
                                            {matchData?.bookmakerRunners &&
                                              matchData?.bookmakerRunners
                                                .length > 0 && (
                                                <span className="game-bookmaker in-play"></span>
                                              )}
                                            {matchData?.bookmakerRunners &&
                                              matchData?.bookmakerRunners
                                                ?.length > 0 &&
                                              matchData?.runners &&
                                              matchData?.runners?.length > 0 &&
                                              matchData?.fancyList &&
                                              matchData?.fancyList.length >
                                                0 && (
                                                <span className="game-sportsbook">
                                                  {" "}
                                                </span>
                                              )}
                                          </Link>
                                        </dt>

                                        <dd className="col-mdl text-end">
                                          USD{" "}
                                          {!isEmpty(matchData?.odds)
                                            ? USDollar.format(
                                                matchData?.odds?.totalMatched
                                              )
                                            : "0"}
                                        </dd>
                                        <OddsListing matchData={matchData} />
                                        <dd className="col-info">
                                          <Link
                                            className={
                                              matchData?.multi_market
                                                ? "add-pin-position"
                                                : "add-pin"
                                            }
                                            onClick={() =>
                                              handelAddMultiMarket(
                                                matchData?.eventId
                                              )
                                            }
                                          ></Link>
                                        </dd>
                                      </dl>
                                    )
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="Tennis">
                      <Accordion.Header>Tennis</Accordion.Header>
                      <Accordion.Body>
                        <div className="game_table_main_wrapper">
                          <div className="game_table_inner">
                            <ul className="slip-head d-flex">
                              <li
                                className="col-game"
                                style={{ width: "calc(63.8% - 41px)" }}
                              ></li>
                              <li className="col-matched text-end">Matched</li>
                              <li className="col-visit text-center">1</li>
                              <li className="col-home text-center">2</li>
                              <li className="col-info"></li>
                            </ul>

                            <div className="game-list">
                              {getInPlaySports &&
                                getInPlaySports.map((matchData, matchKey) => {
                                  let obj = moment.duration(
                                    moment(matchData?.eventDateTime).diff(
                                      moment(new Date())
                                    )
                                  )._data;
                                  let scoreNew =
                                    score?.length > 0
                                      ? score?.find((res) => {
                                          return (
                                            res?.eventId == matchData?.eventId
                                          );
                                        })
                                      : {};
                                  return (
                                    matchData.eventType == "2" && (
                                      <dl
                                        className="game-list-col d-flex"
                                        key={matchKey + 1}
                                      >
                                        <dt className="col-matched">
                                          <Link
                                            to={
                                              "/markets/cricket/" +
                                              matchData.eventId +
                                              "/" +
                                              matchData.marketId
                                            }
                                          >
                                            {matchData?.status == "in_play" ? (
                                              <img
                                                className="icon-in_play"
                                                src="../assets/images/transparent.gif"
                                                alt="No_Image"
                                              />
                                            ) : (
                                              <img
                                                className="icon-no_play"
                                                src="../assets/images/transparent.gif"
                                              />
                                            )}

                                            {!isEmpty(scoreNew) ? (
                                              <span className="game-titlesmall">
                                                {
                                                  matchData?.eventName.split(
                                                    "v"
                                                  )[0]
                                                }
                                                <span className="in_play">
                                                  {scoreNew?.score}
                                                </span>
                                                {
                                                  matchData?.eventName.split(
                                                    "v"
                                                  )[1]
                                                }
                                              </span>
                                            ) : (
                                              <span className="game-titlesmall">
                                                {matchData?.eventName}
                                              </span>
                                            )}
                                            {obj.days == 0 &&
                                            obj.hours == 0 &&
                                            obj?.years == 0 &&
                                            obj?.months == 0 &&
                                            obj.minutes > 0 &&
                                            obj?.minutes <
                                              loginUserDetailData?.beforeInPlay ? (
                                              <span className="game-list-time">
                                                Starting in {obj?.minutes}'
                                              </span>
                                            ) : matchData.status ==
                                              "in_play" ? (
                                              <span className="in_play">
                                                In-Play {matchData?.timeElapsed}
                                              </span>
                                            ) : (
                                              ""
                                            )}

                                            {matchData?.channel !== "false" && (
                                              <span className="game-live"></span>
                                            )}

                                            {matchData?.fancyList &&
                                              matchData?.fancyList.length >
                                                0 && (
                                                <span className="game-fancy in-play"></span>
                                              )}
                                            {matchData?.bookmakerRunners &&
                                              matchData?.bookmakerRunners
                                                .length > 0 && (
                                                <span className="game-bookmaker in-play"></span>
                                              )}
                                            {matchData?.bookmakerRunners &&
                                              matchData?.bookmakerRunners
                                                ?.length > 0 &&
                                              matchData?.runners &&
                                              matchData?.runners?.length > 0 &&
                                              matchData?.fancyList &&
                                              matchData?.fancyList.length >
                                                0 && (
                                                <span className="game-sportsbook">
                                                  {" "}
                                                </span>
                                              )}
                                          </Link>
                                        </dt>

                                        <dd className="col-mdl text-end">
                                          USD{" "}
                                          {!isEmpty(matchData?.odds)
                                            ? USDollar.format(
                                                matchData?.odds?.totalMatched
                                              )
                                            : "0"}
                                        </dd>
                                        <OddsListing matchData={matchData} />
                                        <dd className="col-info">
                                          <Link
                                            className={
                                              matchData?.multi_market
                                                ? "add-pin-position"
                                                : "add-pin"
                                            }
                                            onClick={() =>
                                              handelAddMultiMarket(
                                                matchData?.eventId
                                              )
                                            }
                                          ></Link>
                                        </dd>
                                      </dl>
                                    )
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="Soccer">
                      <Accordion.Header>Soccer</Accordion.Header>
                      <Accordion.Body>
                        <div className="game_table_main_wrapper">
                          <div className="game_table_inner">
                            <ul className="slip-head d-flex">
                              <li className="col-game"></li>

                              <li className="col-matched text-end">Matched</li>

                              <li className="col-visit text-center">1</li>
                              <li className="col-draw text-center">x</li>
                              <li className="col-home text-center">2</li>
                              <li className="col-info"></li>
                            </ul>

                            <div className="game-list">
                              {getInPlaySports &&
                                getInPlaySports.map((matchData, matchKey) => {
                                  let obj = moment.duration(
                                    moment(matchData?.eventDateTime).diff(
                                      moment(new Date())
                                    )
                                  )._data;
                                  let scoreNew =
                                    score?.length > 0
                                      ? score?.find((res) => {
                                          return (
                                            res?.eventId == matchData?.eventId
                                          );
                                        })
                                      : {};
                                  return (
                                    matchData.eventType == "1" && (
                                      <dl
                                        className="game-list-col d-flex"
                                        key={matchKey + 1}
                                      >
                                        <dt className="col-matched">
                                          <Link
                                            to={
                                              "/markets/cricket/" +
                                              matchData.eventId +
                                              "/" +
                                              matchData.marketId
                                            }
                                          >
                                            {matchData?.status == "in_play" ? (
                                              <img
                                                className="icon-in_play"
                                                src="../assets/images/transparent.gif"
                                                alt="No_Image"
                                              />
                                            ) : (
                                              <img
                                                className="icon-no_play"
                                                src="../assets/images/transparent.gif"
                                              />
                                            )}

                                            {/* <span className="game-low_liq d-none">Low Liquidity</span> */}
                                            {!isEmpty(scoreNew) ? (
                                              <span className="game-titlesmall">
                                                {
                                                  matchData?.eventName.split(
                                                    "v"
                                                  )[0]
                                                }
                                                <span className="in_play">
                                                  {scoreNew?.score}
                                                </span>
                                                {
                                                  matchData?.eventName.split(
                                                    "v"
                                                  )[1]
                                                }
                                              </span>
                                            ) : (
                                              <span className="game-titlesmall">
                                                {matchData?.eventName}
                                              </span>
                                            )}
                                            {obj.days == 0 &&
                                            obj.hours == 0 &&
                                            obj?.years == 0 &&
                                            obj?.months == 0 &&
                                            obj.minutes > 0 &&
                                            obj?.minutes <
                                              loginUserDetailData?.beforeInPlay ? (
                                              <span className="game-list-time">
                                                Starting in {obj?.minutes}'
                                              </span>
                                            ) : matchData.status ==
                                              "in_play" ? (
                                              <span className="in_play">
                                                In-Play {matchData?.timeElapsed}
                                              </span>
                                            ) : (
                                              ""
                                            )}

                                            {matchData?.channel !== "false" && (
                                              <span className="game-live"></span>
                                            )}

                                            {matchData?.fancyList &&
                                              matchData?.fancyList.length >
                                                0 && (
                                                <span className="game-fancy in-play"></span>
                                              )}
                                            {matchData?.bookmakerRunners &&
                                              matchData?.bookmakerRunners
                                                .length > 0 && (
                                                <span className="game-bookmaker in-play"></span>
                                              )}
                                            {matchData?.bookmakerRunners &&
                                              matchData?.bookmakerRunners
                                                ?.length > 0 &&
                                              matchData?.runners &&
                                              matchData?.runners?.length > 0 &&
                                              matchData?.fancyList &&
                                              matchData?.fancyList.length >
                                                0 && (
                                                <span className="game-sportsbook">
                                                  {" "}
                                                </span>
                                              )}
                                          </Link>
                                        </dt>

                                        <dd className="col-mdl text-end">
                                          USD{" "}
                                          {!isEmpty(matchData?.odds)
                                            ? USDollar.format(
                                                matchData?.odds?.totalMatched
                                              )
                                            : "0"}
                                        </dd>
                                        <OddsListing matchData={matchData} />
                                        <dd className="col-info">
                                          <Link
                                            className={
                                              matchData?.multi_market
                                                ? "add-pin-position"
                                                : "add-pin"
                                            }
                                            onClick={() =>
                                              handelAddMultiMarket(
                                                matchData?.eventId
                                              )
                                            }
                                          ></Link>
                                        </dd>
                                      </dl>
                                    )
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </Tab>

              <Tab eventKey="today" title="Today">
                <div className="today_tab">
                  <div className="sports_filters">
                    <div className="d-flex">
                      <h5>Sports Filters:</h5>
                      <ul className="d-flex align-items-center ">
                        <li>Cricket</li>
                        <li>Soccer</li>
                        <li>Tennis</li>
                      </ul>
                    </div>

                    <div className="ms-auto mb-2">
                      <OverlayTrigger
                        trigger="click"
                        placement="bottom"
                        overlay={popover}
                        rootClose={true}
                      >
                        <Button className="close_btn">Filter</Button>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>

                <Table className="today_table bg-white table table-fixed table-hover">
                  <tbody>
                    {getAllSports?.toDay &&
                      getAllSports?.toDay.map((toDayData, toDayKey) => (
                        <tr key={toDayKey + 1}>
                          <td>
                            {helpers.matchDateTime(toDayData.eventDateTime)}
                          </td>
                          <td>
                            <div className="table_data">
                              <h5>
                                {startCase(
                                  helpers.getSportType(
                                    parseInt(toDayData?.eventType)
                                  )
                                )}
                                <span className="angle_unicode">&#9656;</span>
                                {toDayData?.tournament?.seriesName}
                                <span className="angle_unicode">&#9656;</span>
                                <Link
                                  to={
                                    "/markets/" +
                                    helpers.getSportType(
                                      parseInt(toDayData?.eventType)
                                    ) +
                                    "/" +
                                    toDayData.eventId +
                                    "/" +
                                    toDayData.marketId
                                  }
                                >
                                  {toDayData?.eventName}
                                </Link>
                              </h5>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Tab>

              <Tab eventKey="tomorrow" title="Tomorrow">
                <div className="today_tab">
                  <div className="sports_filters">
                    <div className="d-flex">
                      <h5>Sports Filters:</h5>
                      <ul className="d-flex align-items-center ">
                        <li>Cricket</li>
                        <li>Soccer</li>
                        <li>Tennis</li>
                      </ul>
                    </div>

                    <div className="ms-auto mb-2">
                      <OverlayTrigger
                        trigger="click"
                        placement="bottom"
                        overlay={popover}
                        rootClose={true}
                      >
                        <Button className="close_btn">Filter</Button>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>

                <Table className="today_table bg-white table table-fixed table-hover">
                  <tbody>
                    {getAllSports?.tomorrow &&
                      getAllSports?.tomorrow.map(
                        (tomorrowData, tomorrowKey) => (
                          <tr key={tomorrowKey + 1}>
                            <td>
                              {helpers.matchDateTime(
                                tomorrowData.eventDateTime
                              )}
                            </td>
                            <td>
                              <div className="table_data">
                                <h5>
                                  {startCase(
                                    helpers.getSportType(
                                      parseInt(tomorrowData?.eventType)
                                    )
                                  )}
                                  <span className="angle_unicode">&#9656;</span>
                                  {tomorrowData?.tournament?.seriesName}
                                  <span className="angle_unicode">&#9656;</span>
                                  <a
                                    href={
                                      "/markets/" +
                                      helpers.getSportType(
                                        parseInt(tomorrowData?.eventType)
                                      ) +
                                      "/" +
                                      tomorrowData.eventId +
                                      "/" +
                                      tomorrowData.marketId
                                    }
                                  >
                                    {tomorrowData?.eventName}
                                  </a>
                                </h5>
                              </div>
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </Table>
              </Tab>
            </Tabs>
          </div>
        </div>
        {/* right_cols */}
        <Rightbar />
      </div>
    </>
  );
};

export default Inplay;
let USDollar = new Intl.NumberFormat("en-US", {
  currency: "USD",
});
