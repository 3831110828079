import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import { isEmpty } from "lodash";
import helpers from "../utils/helpers";
import AuthContext from "../context/AuthContext";
import ReactPaginate from "react-paginate";

const InternationalCasionBets = () => {
  let { user } = useContext(AuthContext);
  const params = useParams();
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [search_params, setSearchParams] = useState({
    page: 1,
    limit: 50,
  });
  const getData = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.getInternationalCasinoBets,
      search_params
    );
    if (status === 200) {
      if (response_users.success) {
        setData(response_users.results?.data);
      }
    }
  };

  const handlePageClick = (event) => {
    setSearchParams((prevState) => {
      return {
        ...prevState,
        page: event.selected + 1,
      };
    });
  };

  useEffect(() => {
    setPageCount(data?.totalPages || []);
  }, [data]);

  useEffect(() => {
    getData();
  }, [search_params]);

console.log("data",data);

  return (
    <>
      <section className="py-4 main-inner-outer">
        <Container fluid>
          <div className="main_content_row">
            <Sidebar />

            <div className="my_account_main">
              <div className="inner-wrapper">
                <h2 className="common-heading" style={{float:"left"}}>Account Statement</h2>
                <Link
                  to={ `/InternationalCasionBets/`}
                  // target="_blank"
                  className="green-btn"
                  style={{float:"left",marginLeft: "20px",marginBottom:"10px", marginTop:"-5px", padding:"5px"}}
                  >
                  International Casino Transaction
                </Link>
                <section className="account-table">
                  <div className="profile-tab">
                    <div className="my_account_table_wrap">
                      <div className="table-main-wrapper">
                      <Table className="banking_detail_table">
                            <thead>
                                <tr>
                                <th scope="col">Casino Bet Id</th>
                                <th scope="col">Client</th>
                                <th scope="col">Casino Name</th>
                                <th scope="col">Stake</th>
                                <th scope="col">Place Time</th>
                                <th scope="col">Profit/Loss</th>
                                </tr>
                            </thead>
                            {data && data?.length > 0 ? (
                                    data?.map((item) => {
                                        let gameName;
                                        if(item?.gameCode !="1006" && item?.gameInfo)
                                        {
                                            const jdata = JSON.parse(item?.gameInfo);
                                            gameName = jdata?.gameName;
                                        }else{
                                            gameName = item?.gameInfo;
                                        }
                                        return (
                                            <tr>
                                                <td>{item?.casinoBetId || "N/A"}</td>
                                                <td> {item?.clientName || "N/A"}</td>
                                                <td> {`${item?.platform} | ${gameName}` || "N/A"}</td>
                                                <td> {item?.betAmount || "N/A"}</td>
                                                <td> {item?.timeInserted || "N/A"} </td>
                                                <td>
                                                    {item?.realCutAmount>0? (
                                                    <span className="text-success">
                                                        {helpers.currencyFormat(item?.realCutAmount)}
                                                    </span>
                                                    ) : (
                                                    <span className="text-danger">
                                                        {"(" +
                                                        helpers.currencyFormat(item?.realCutAmount) +
                                                        ")"}
                                                    </span>
                                                    )}
                                                </td>
                                                
                                            </tr>
                                        )})
                                    ) : (
                                        <tr>
                                        <td colSpan={10}>
                                            <span>You have no bets in this time period.</span>
                                        </td>
                                        </tr>
                                    )}
                            </Table>
                        <div className="bottom-pagination">
                          <ReactPaginate
                            breakLabel="..."
                            nextLabel=" >"
                            // forcePage={viewpage}
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={10}
                            pageCount={pageCount}
                            previousLabel="< "
                            renderOnZeroPageCount={null}
                            activeClassName="p-1"
                            activeLinkClassName="pagintion-li"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default InternationalCasionBets;
