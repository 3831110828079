import React, { useState, useContext, useEffect } from "react";
import { Col, Row, Form, Modal, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { useForm } from "react-hook-form";
import { apiPost } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import { toast } from "wc-toast";

const CasinoModal = () => {
  const navigate = useNavigate();
  let {
    user,
    user_coins,
    casinoRangeAmount,
    casinoShow,
    modalShow,
    getCasinoAmount,
    gameType,
    platForm,
    casinoType,
  } = useContext(AuthContext);
  const [isLoader, setLoader] = useState(false);
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    reset: reset2,
  } = useForm({});

  const onSubmit2 = async (data) => {
    setLoader(true);
    const { status, data: response_users1 } = await apiPost(
      apiPath.casinoAmountAdd,
      { amount: getCasinoAmount }
    );
    if (status === 200) {
      if (response_users1.success) {
        const { status, data: response_users } = await apiPost(
          platForm && gameType
            ? apiPath.doLoginAndLaunchGame
            : apiPath.casinoWalletLogin,
          platForm && gameType
            ? {
              userId: response_users1?.results?.aeCasinoUserId,
              platForm: platForm,
              gameType: gameType,
              gameCode: casinoType
            }
            : { userId: response_users1?.results?.aeCasinoUserId }
        );
        if (status === 200) {
          if (response_users.status) {
            if (response_users.data.status === "0000") {
              //console.log("response_users", response_users.data.url);
              setLoader(false);
              reset2();
              javascript: window.open(
                response_users.data.url,
                "_blank",
                "height=900,width=1200"
              );
              casinoShow(false);
            } else {
              setLoader(false);
              toast.error(response_users.data?.desc);
            }
          } else {
            setLoader(false);
            toast.error(response_users.message);
          }
        }
      } else {
        setLoader(false);
        toast.error(response_users1.message);
      }
    } else {
      setLoader(false);
      toast.error(response_users1.message);
    }
  };
  const onSubmit1 = async (data) => {
    setLoader(true);
    try {
      const { status, data: response_users1 } = await apiPost(
        casinoType === "aesexy"
          ? apiPath.casinoAmountAdd
          : apiPath.easytogoCasinoAmountAdd,
        { amount: getCasinoAmount }
      );
      if (status === 200) {
        if (response_users1.success) {
          setLoader(false);

          navigate("/live-casino");
          casinoShow(false);
          reset2();
        } else {
          setLoader(false);
          toast.error(response_users1.message);
        }
      } else {
        setLoader(false);
        toast.error(response_users1.message);
      }
    } catch (err) {
      console.log("dddd", err);
      setLoader(false);
      toast.error(err.message);
    }
  };

  return (
    <div>
      <Modal
        show={modalShow}
        onHide={casinoShow}
        centered
        className="casino_modal"
      >
        <Modal.Header closeButton className="p-0 py-2 px-3">
          <Modal.Title>Casino</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 mb-5">
          <Row>
            <Col md={6}>
              <div className="casino_main p-3">
                <h6>Main Balance</h6>
                <h5>{user_coins?.totalCoins?.toFixed(2)}</h5>
              </div>
            </Col>

            <Col md={6} className="ps-0">
              <div className="casino_main text-end p-3 casino_border">
                <h6>Casino Balance</h6>
                <h5> {user_coins?.casinoCoins?.toFixed(2)}</h5>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Form
          className="position-relative"
          onSubmit={handleSubmit2(
            casinoType === "livecasino" ? onSubmit1 : onSubmit2
          )}
        >
          <div className="price_range">
            <div className="casino_range ">
              <Row className="align-items-center">
                <Col md={2} className="">
                  <h4 className="m-0 text-center">0</h4>
                </Col>
                <Col md={8}>
                  <Form.Group
                    className="mb-3 range_input"
                    controlId="formBasicEmail1"
                  >
                    <Form.Control
                      type="number"
                      placeholder="Amount"
                      value={getCasinoAmount ? getCasinoAmount : ""}
                      onChange={(e) => casinoRangeAmount(e)}
                      onKeyDown={event => {
                        if (event.key === "E" || event.key === "e" || event.key === "+" || event.key === "-") {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Group>
                  <div class="balance-percent" style={{ width: `58%` }}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <Form.Range
                    value={getCasinoAmount}
                    min="0"
                    max={user_coins?.totalCoins?.toFixed(2)}
                    onChange={(e) => casinoRangeAmount(e)}
                  />
                </Col>
                <Col md={2}>
                  {" "}
                  <h4 className="me-2 m-0 text-center">Max</h4>
                </Col>
              </Row>
            </div>
          </div>
          <Modal.Footer className="p-0">
            <ul className="btn-wrap w-100">
              {" "}
              <li className="close_btn me-4">
                <Link
                  to="#"
                  id="closeSet"
                  className="btn border-0 py-2"
                  onClick={casinoShow}
                >
                  Cancel
                </Link>
              </li>
              <li className="col-send">
                <Button
                  type="submit"
                  className="btn-send m-0 py-2"
                  disabled={isLoader ? true : false}
                >
                  {isLoader ? "Loading..." : "Transfer and Enter"}{" "}
                  <img className="icon-login ms-1" alt="" />
                </Button>
              </li>
            </ul>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default CasinoModal;
