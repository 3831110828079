import moment from "moment";
import React, { useState } from "react";
import { Dropdown, Accordion, NavDropdown } from "react-bootstrap";
import transparentGif from "../../src/assets/images/transparent.gif";
import { apiPost } from "../utils/apiFetch";
import helpers from "../utils/helpers";
import apiPath from "../utils/apiPath";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { toast } from "wc-toast";
import Login from "./Login";
import { useEffect } from "react";
import BetSlipContext from "../context/BetSlipContext";
import Loader from "./Loader";
import loading40 from "../../src/assets/images/loading40.gif";
const isInputNumber = helpers.isInputNumber;

const Rightbar = () => {
  let {
    selections,
    handelRemoveBackBetSlip,
    handelChangeBackAll,
    handelSetPrice,
    backBetSlipArray,
    layBetSlipArray,
    handelRemoveLayBetSlip,
    handelChangeLayAll,
    allBetSlipData,
    handelClearAllBet,
    handelPlaceBetfair,
    showLogin,
    setShowLogin,
    selectedSelectins,
    disableBetFairPlaceBetBtn,
    loaderPlaceBetfair,
    betFairLiability,
    showToggle,
    minAmountCon,
    betLoader,
    timeLeft,
  } = useContext(BetSlipContext);

  //All STATE

  //******************** FUNCTIONS ******************************//

  //***************** RENDER UI FOR BET SLIP ******************************//
  const renderBackBetSlip = (backBetSlip) => {
    return (
      <>
        {backBetSlip.length !== 0 && (
          <ul className="slip-head d-flex">
            <li className="col-bet">Back (Bet For)</li>
            <li id="oddsHeader" className="col-odd">
              Odds
            </li>
            <li id="runsHeader" className="col-odd">
              Unit:Runs
            </li>
            <li className="col-stake">Stake</li>
            {/* <li className="col-profit text-end">Profit</li> */}
          </ul>
        )}

        {backBetSlip &&
          backBetSlip.length !== 0 &&
          backBetSlip.map((data, index) => {
            return (
              <div className="team_list_wrapper blue_bg_tuch" key={index + 1}>
                <h4>
                  <img
                    className="icon-in_play"
                    src={transparentGif}
                    alt="No_Image"
                  />{" "}
                  {data?.runners[0]?.RunnerName} v&nbsp;
                  {data?.runners[1]?.RunnerName}
                </h4>
                <div className="match_oddds">
                  <span className="d-flex align-center justify-content-center">
                    <i
                      className="fa fa-window-close bet-slip-cross-icon"
                      onClick={() => handelRemoveBackBetSlip(index)}
                    ></i>
                    <h5>
                      {data?.teamName}
                      <small>Match Odds</small>
                    </h5>
                  </span>
                  <div className="up_down_rate">
                    <ul>
                      <li>
                        <input
                          type="text"
                          value={data?.rt}
                          disabled
                          className="bet-slip-first-input"
                        />
                      </li>
                      <li>
                        <input
                          type="number"
                          value={data?.activePrice}
                          name="back-tik"
                          onChange={(e) => handelChangeBackAll(e, index, data)}
                          onKeyPress={(e) => {
                            isInputNumber(e);
                            if (e.target.value.length > 6) {
                              e.preventDefault();
                            }
                          }}
                          max={1500000}
                          style={
                            !data?.activePrice
                              ? {
                                  border: "1px solid red",
                                  cursor: "pointer",
                                  borderRadius: "4px",
                                }
                              : {}
                          }
                          title={!data?.activePrice ? "Enter amount" : ""}
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="odds_value">{`${
                    data.rt && data.activePrice
                      ? "$" +
                        parseInt((data.rt - 1) * data.activePrice.toFixed(2))
                      : ""
                  }`}</div>
                </div>

                <div className="preset_value">
                  <ul>
                    {data?.price.map((money) => {
                      return (
                        <li key={money?.id}>
                          <button
                            className="price-button-pay-slip"
                            onClick={() => handelSetPrice(money, data)}
                            style={
                              data.activePrice === money.value
                                ? {
                                    background: "#444444",
                                    color: "#f8d61c ",
                                    cursor: "not-allowed",
                                  }
                                : {}
                            }
                            disabled={data.activePrice === money.value}
                          >
                            {money?.value}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="min-bets">
                  Min bet:
                  {data?.minAmount ? data?.minAmount : 10}
                </div>
              </div>
            );
          })}
      </>
    );
  };
  //****** BET FAIR LAY (Pink) BET SLIP CARDS ******//
  const renderLayBetSlip = () => {
    return (
      <>
        {layBetSlipArray.length !== 0 && (
          <ul className="slip-head d-flex">
            <li className="col-bet">Lay (Bet Against)</li>
            <li id="oddsHeader" className="col-odd">
              Odds
            </li>
            <li id="runsHeader" className="col-odd">
              Stake
            </li>
            {/* <li className="col-stake">Liability</li> */}
          </ul>
        )}
        {layBetSlipArray &&
          layBetSlipArray.length !== 0 &&
          layBetSlipArray.map((item, index) => {
            return (
              <div className="team_list_wrapper lay_bg_tuch" key={index + 1}>
                <h4>
                  <img
                    className="icon-in_play"
                    src={transparentGif}
                    alt="No_Image"
                  />{" "}
                  {item?.runners[0]?.RunnerName} v&nbsp;
                  {item?.runners[1]?.RunnerName}
                </h4>
                <div className="match_oddds">
                  <span className="d-flex align-center justify-content-center">
                    <i
                      className="fa fa-window-close bet-slip-cross-icon"
                      onClick={() => handelRemoveLayBetSlip(index)}
                    ></i>
                    <h5>
                      {item?.teamName}
                      <small>Match Odds</small>
                    </h5>
                  </span>
                  <div className="up_down_rate">
                    <ul>
                      <li className="bill-slip-rt">
                        <input
                          type="text"
                          value={item?.rt}
                          disabled
                          className="bet-slip-first-input"
                        />{" "}
                      </li>
                      <li>
                        <input
                          type="number"
                          value={item?.activePrice}
                          onChange={(e) => handelChangeLayAll(e, index, item)}
                          onKeyPress={(e) => {
                            isInputNumber(e);
                            if (e.target.value.length > 6) {
                              e.preventDefault();
                            }
                          }}
                          max={1500000}
                          style={
                            !item?.activePrice
                              ? { border: "1px solid red", borderRadius: "4px" }
                              : {}
                          }
                          title={!item?.activePrice ? "Enter amount" : ""}
                        />{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="odds_value">{`${
                    item.rt && item.activePrice
                      ? "$" + parseInt(item.activePrice.toFixed(2))
                      : ""
                  }`}</div>
                </div>

                <div className="preset_value">
                  <ul>
                    {item?.price.map((money) => {
                      return (
                        <li key={money?.id}>
                          <button
                            className="price-button-pay-slip"
                            onClick={() => handelSetPrice(money, item)}
                            style={
                              item.activePrice === money.value
                                ? {
                                    background: "#444444",
                                    color: "#f8d61c ",
                                    cursor: "not-allowed",
                                  }
                                : {}
                            }
                            disabled={item.activePrice === money.value}
                          >
                            {money?.value}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="min-bets">Min bet:10</div>
              </div>
            );
          })}
      </>
    );
  };

  return (
    <>
      <button
        className="rightbar_btn d-block d-lg-none"
        onClick={() => showToggle()}
      >
        <i className="fa-solid fa-bars"></i>
      </button>

      <div className="col-right">
        <div className="theme_accordian">
          <Accordion defaultActiveKey="bets">
            <Accordion.Item eventKey="bets">
              <Accordion.Header>Bet Slip</Accordion.Header>
              <Accordion.Body>
                <div className="bet_slip_data">
                  <div className="col-right-inner">
                    {betLoader ? (
                      <div class="loading-wrap">
                        <ul class="loading">
                          <li>
                            <img src={loading40} />
                          </li>
                          <li>Place Bets for {timeLeft} secs</li>
                        </ul>
                      </div>
                    ) : (
                      <>
                        <div className="message-bet d-none">
                          <h4 id="errorMsg" className="error">
                            Your back odds cannot bet less than or the same as
                            your lay odds for Martinez v S Kwon, Match Odds,
                            Soonwoo Kwon
                          </h4>
                          <p id="betMsg" className="warning"></p>
                        </div>

                        <p className="d-none">
                          Click on the odds to add selections to the betslip.{" "}
                        </p>

                        <div id="betSlipBoard" className="bet_slip">
                          <div
                            id="betList"
                            className="slip-list max-heightminus131"
                          >
                            {minAmountCon && (
                              <div class="message-bet">
                                <h4 id="errorMsg" class="error">
                                  The stake you have entered are below the
                                  minimum.
                                </h4>
                              </div>
                            )}

                            <div className="bets_list_wrapper">
                              {/* BLUE PART START */}
                              {renderBackBetSlip(backBetSlipArray)}
                              {/* BLUE PART END */}

                              <div>
                                {/* PINK PART START */}
                                {renderLayBetSlip()}
                                {/* PINK PART END */}
                              </div>
                            </div>
                          </div>
                        </div>

                        {allBetSlipData.length > 0 ? (
                          <div className="full_btn">
                            <div className="liability sum">
                              Liability
                              <span className="red">
                                $
                                {betFairLiability
                                  ? parseFloat(
                                      Math.abs(betFairLiability)
                                    ).toFixed(2)
                                  : 0}
                              </span>
                            </div>

                            <ul className="btn-wrap">
                              <li>
                                {/* <a className="btn" style={{ cursor: "pointer" }}>
                              Cancel All{" "}
                            </a> */}
                                <button
                                  className="price-button-pay-slip btn"
                                  onClick={() => handelClearAllBet()}
                                >
                                  Cancel All
                                </button>
                              </li>
                              <li>
                                <button
                                  className="btn-send disable"
                                  style={
                                    disableBetFairPlaceBetBtn ||
                                    loaderPlaceBetfair
                                      ? {
                                          cursor: "not-allowed",
                                        }
                                      : {
                                          cursor: "pointer",
                                        }
                                  }
                                  onClick={() => handelPlaceBetfair()}
                                  disabled={
                                    disableBetFairPlaceBetBtn ||
                                    loaderPlaceBetfair
                                  }
                                  title={
                                    disableBetFairPlaceBetBtn
                                      ? "Please fill amount field"
                                      : ""
                                  }
                                >
                                  Place Bets{" "}
                                  {loaderPlaceBetfair && (
                                    <span style={{ marginLeft: "10px" }}>
                                      <Loader
                                        Style={{
                                          borderTop: "7px solid #FFB80C",
                                          borderBottom: "7px solid #FFB80C",
                                          width: "10px",
                                          height: "10px",
                                        }}
                                      />
                                    </span>
                                  )}
                                </button>
                              </li>
                            </ul>

                            <ul className="slip-option">
                              <li>
                                <input type="checkbox" />
                                <label>Please confirm your bets.</label>{" "}
                              </li>
                            </ul>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-center align-item-center mt-2 px-3">
                            <p style={{ fontSize: "13px" }}>
                              Click on the odds to add selections to the
                              betslip.
                            </p>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      <Login show={showLogin} handleClose={() => setShowLogin(false)} />
    </>
  );
};

export default Rightbar;
